import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button} from "@mui/material";
import dayjs from "dayjs";
import 'dayjs/locale/de';
import duration from 'dayjs/plugin/duration';
import CalendarField from "./CalendarField";
import {useCalendarSelectionContext} from "../globals/CalendarContext";

dayjs.locale('de');
dayjs.extend(duration);

CalendarTemplate.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    startDate: PropTypes.string,
    t: PropTypes.func,
    calendarData: PropTypes.object,
    isMobile: PropTypes.bool,
    handleDateSelect: PropTypes.func,
};

const getMondayBasedWeekdayIndex = (dayjsDate) => {
    let dayIndex = dayjsDate.day() - 1; // Adjust so Monday is 0, ..., Sunday is 6
    if (dayIndex === -1) dayIndex = 6; // Make Sunday 6
    return dayIndex;
};

function CalendarTemplate(props) {
    // testDayjs.js
    // get next two months from today starting at first day of the month in which the startDate is with dayjs

    const t = props.t;
    const fallbackDate = (props.startDate) ? props.startDate : (props.calendarData['date-fields']['cal-first-day']) ? props.calendarData['date-fields']['cal-first-day'] : dayjs().format('YYYY-MM-DD')
    const initialStartDate = dayjs(fallbackDate);
    const [startDate, setStartDate] = useState(() => dayjs(initialStartDate));
    const [calendarGrid1, setCalendarGrid1] = useState([]);
    const [calendarGrid2, setCalendarGrid2] = useState([]);
    const [firstDayOfTheMonth1, setFirstDayOfTheMonth1] = useState(initialStartDate.startOf('month'));
    const [firstDayOfTheMonth2, setFirstDayOfTheMonth2] = useState(initialStartDate.add(1, 'month').startOf('month'));
    const [isLoading, setIsLoading] = useState(true);
    const [flexDirection, setFlexDirection] = useState('column');
    const {setCleanUp} = useCalendarSelectionContext();

    const weekDays = [t('calendar.days.1.short'), t('calendar.days.2.short'), t('calendar.days.3.short'), t('calendar.days.4.short'), t('calendar.days.5.short'), t('calendar.days.6.short'), t('calendar.days.0.short')];

    const initializeCalendarGrid = (monthDate) => {
        const startDay = dayjs(monthDate);
        const daysInMonth = startDay.daysInMonth();
        const firstDayIndex = getMondayBasedWeekdayIndex(startDay);

        // Calculate total slots needed (days in month + preceding nulls for alignment)
        const totalSlots = firstDayIndex + daysInMonth;
        const totalWeeks = Math.ceil(totalSlots / 7);

        let calendarGrid = new Array(totalWeeks).fill(null).map(() => new Array(7).fill(null));

        // Populate the calendar grid
        for (let day = 1, week = 0, dayOfWeek = firstDayIndex; day <= daysInMonth; day++, dayOfWeek++) {
            if (dayOfWeek > 6) { // Move to the next week
                week++;
                dayOfWeek = 0;
            }
            calendarGrid[week][dayOfWeek] = startDay.date(day);
        }

        return calendarGrid;
    };

    useEffect(() => {
        // Since dayjs object is immutable, it's safe to use startDate directly for calculations
        const firstDayOfTheMonth1 = startDate.startOf('month');
        const secondMonth = startDate.add(1, 'month');
        const firstDayOfTheMonth2 = secondMonth.startOf('month');
        setFirstDayOfTheMonth1(startDate.startOf('month'));
        setFirstDayOfTheMonth2(startDate.add(1, 'month').startOf('month'));

        // Your existing logic to populate calendar grids
        const newCalendarGrid1 = initializeCalendarGrid(firstDayOfTheMonth1);
        const newCalendarGrid2 = initializeCalendarGrid(firstDayOfTheMonth2);

        setCalendarGrid1(newCalendarGrid1);
        setCalendarGrid2(newCalendarGrid2);

        setIsLoading(false);
    }, [startDate, t]);

    const cleanUpCalendar = () => {
        let calendarTemplate = document.getElementById('sb-calendar-default-template');
        if (!calendarTemplate) {
            return;
        }
        let calendarFields = calendarTemplate.getElementsByClassName('selected');
        calendarFields = Array.from(calendarFields);
        calendarFields.forEach((field) => {
            field.classList.remove('selected');
            field.classList.remove('selected-date-change');
        });

        let calAmountDaysField = document.getElementById("sb-product-card-amount-days-value");
        if (calAmountDaysField !== undefined) {
            calAmountDaysField.innerText = t('product.nothing-selected');
        }
    }

    const handlePreviousMonth = () => {
        // Logic to handle previous month navigation
        setStartDate(prev => prev.subtract(1, 'month'));
        setCleanUp(true);
        cleanUpCalendar();
    };

    const handleNextMonth = () => {
        // Logic to handle next month navigation
        setStartDate(prev => prev.add(1, 'month'));
        setCleanUp(true);
        cleanUpCalendar();
    };

    const handledateClick = (e, day) => {

        let useAnimation = true;
        if (props.handleDateSelect) {
            useAnimation = props.handleDateSelect(e, day);
        }

        if (useAnimation) {
            if (e.target.classList.contains('not-selectable')) {
                return;
            } else {
                if (!e.target.classList.contains('free') && !e.target.classList.contains('less')) {
                    e.target.classList.add('pulse-animation');
                    setTimeout(() => {
                        e.target.classList.remove('pulse-animation');
                    }, 200);
                } else {
                    if (e.target.classList.contains('selected-date-change') && e.target.classList.contains('selected')) {
                        e.target.classList.remove('selected-date-change');
                        e.target.classList.remove('selected');
                    } else {
                        e.target.classList.add('select-date-animation');
                        setTimeout(() => {
                            e.target.classList.remove('select-date-animation');
                        }, 200);
                        e.target.classList.add('selected-date-change')
                        e.target.classList.add('selected');
                    }
                }
            }
        }
    }

    if (isLoading) {
        return (
            <Box>
                {t('calendar.loading')}
            </Box>
        )
    }

    return (
        <Box
            id={'sb-calendar-default-template'}
            sx={{
                display: 'flex',
                flexDirection: flexDirection,
                justifyContent: 'center',
                minWidth: '150px',
                minHeight: '300px',
                maxWidth: '200px',
                width: '100%',
                // border: '1px solid black',
                '& table': {
                    textAlign: 'center',
                    width: '100%',
                    borderCollapse: 'collapse',
                    '& thead': {
                        '& th': {
                            borderBottom: '1px solid black',
                            padding: '5px 0',
                            fontWeight: 'bold',
                        }
                    },
                    '& tbody': {
                        '& td': {
                            // border: '1px solid black',
                            padding: '15px',
                            '&.hoverable': {
                                transition: 'background-color 0.5s',
                                '&:hover': {
                                    backgroundColor: '#89c2ff',
                                    cursor: 'pointer',
                                },
                            },
                            '&.free': {
                                backgroundColor: '#76dc73',
                            },
                            '&.less': {
                                backgroundColor: 'orange',
                            },
                            '&.not-selectable': {
                                backgroundColor: '#dadada',
                            },
                            '&.today': {
                                color: 'blue',
                            },
                            '&.in-selection-range.selected': {
                                background: '#7cacea !important',
                            },
                            '&.selected': {
                                backgroundColor: '#7cacea',
                            },
                            '&.in-selection-range': {
                                '&:not(.free):not(.less)': {
                                    background: 'rgba(144,238,144,0.6)',
                                },
                            },
                        }
                    }
                }
            }}
        >
            <table style={{width: '100%'}}>
                <thead>
                    <tr>
                        <th colSpan={7}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Button
                                    onClick={handlePreviousMonth}
                                >
                                    {t('calendar.previous')}
                                </Button>
                                <Button
                                    onClick={handleNextMonth}
                                >
                                    {t('calendar.next')}
                                </Button>
                            </Box>
                        </th>
                    </tr>
                    <tr>
                        <th colSpan={7}>
                            {t(`calendar.months.${firstDayOfTheMonth1.month()}`)} {firstDayOfTheMonth1.format('YYYY')}
                        </th>
                    </tr>
                    <tr>
                        {
                            weekDays.map((day, index) => {
                                return (
                                    <th key={index}>
                                        {day}
                                    </th>
                                );
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        calendarGrid1.map((week, index) => {
                            return (
                                <tr key={index}>
                                    {
                                        week.map((day, index) => {
                                            return <CalendarField key={'grid1-' + index} day={day} index={index} dateFields={props.calendarData["date-fields"]} onClick={handledateClick}/>
                                        })
                                    }
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            <table style={{width: '100%', marginTop: '10px'}}>
                <thead>
                <tr>
                    <th colSpan={7}>
                        {t(`calendar.months.${firstDayOfTheMonth2.month()}`)} {firstDayOfTheMonth2.format('YYYY')}
                    </th>
                </tr>
                <tr>
                    {
                        weekDays.map((day, index) => {
                            return (
                                <th key={index}>
                                    {day}
                                </th>
                            );
                        })
                    }
                </tr>
                </thead>
                <tbody>
                {
                    calendarGrid2.map((week, index) => {
                        return (
                            <tr key={index}>
                                {
                                    week.map((day, index) => {
                                        return <CalendarField key={'grid2-' + index} day={day} index={index} dateFields={props.calendarData["date-fields"]} onClick={handledateClick} />
                                    })
                                }
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
        </Box>
    );
}

export default CalendarTemplate;