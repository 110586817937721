import React, {useEffect} from 'react';
import {useCalendarSelectionContext} from "../globals/CalendarContext";
import CalendarTemplate from "./CalendarTemplate";
import dayjs from "dayjs";

function CalendarPreSelection({ startDate, t, calendarData, isMobile }) {

    const [firstDate, setFirstDate] = React.useState(null);
    const [lastDate, setLastDate] = React.useState(null);
    const {putSelectedDates, cleanUp} = useCalendarSelectionContext();

    const cleanUpCalendar = () => {
        let calendarTemplate = document.getElementById('sb-calendar-default-template');
        if (!calendarTemplate) {
            return;
        }
        let calendarFields = calendarTemplate.getElementsByClassName('selected');
        calendarFields = Array.from(calendarFields);
        calendarFields.forEach((field) => {
            field.classList.remove('selected');
            field.classList.remove('selected-date-change');
        });

        let calAmountDaysField = document.getElementById("sb-product-card-amount-days-value");
        if (calAmountDaysField !== undefined) {
            calAmountDaysField.innerText = t('product.nothing-selected');
        }
    }

    const handleDateSelect = (e, day) => {
        if (firstDate !== null && lastDate !== null) {
            setFirstDate(null);
            setLastDate(null);
            return false;
        } else {
            if (e.target.classList.contains('free') || e.target.classList.contains('less')) {
                if (!firstDate) {
                    setFirstDate(day);
                } else {
                    setLastDate(day);
                }
            }
            return true;
        }
    }

    const setAmountDaysValue = (amountDays, empty) => {
        let calAmountDaysField = document.getElementById("sb-product-card-amount-days-value");
        if (calAmountDaysField !== undefined) {
            if (empty) {
                calAmountDaysField.innerText = t('product.nothing-selected');
            } else {
                calAmountDaysField.innerText = (amountDays.toString() + ' ' + t('calendar.days-string')).toString();
            }
        }
    }

    const selectDate = (day) => {
        const fieldId = 'sb-calendar-date-field-' + day.format('YYYY-MM-DD');
        let field = document.getElementById(fieldId);
        if (field) {
            field.classList.add('selected');
            field.classList.add('selected-date-change');
        } else {

        }
    }

    useEffect(() => {
        if (firstDate === null && lastDate === null) {
            let calendarTemplate = document.getElementById('sb-calendar-default-template');
            if (!calendarTemplate) {
                return;
            }
            let calendarFields = calendarTemplate.getElementsByClassName('selected-date-change');
            calendarFields = Array.from(calendarFields);
            calendarFields.forEach((field) => {
                field.classList.remove('selected');
                field.classList.remove('selected-date-change');
            });
            putSelectedDates();
            setAmountDaysValue(0, true);
        } else {
            if (firstDate && !lastDate) {
                // console.log(firstDate);
                if (calendarData && calendarData["date-fields"] && calendarData["date-fields"]['day-connections'] !== undefined && calendarData["date-fields"]['day-connections'] !== null) {
                    calendarData["date-fields"]['day-connections'].map((connection, index) => {
                        if (connection.day === firstDate.format('YYYY-MM-DD')) {
                            // console.log(connection);
                            connection.days_between.map((day) => {
                                // format day to dayjs object
                                let date = dayjs(day);
                                selectDate(date);
                            });
                            setAmountDaysValue(connection.amount_days, false);
                            setLastDate(dayjs(connection.end_date));
                        }
                    });
                }
            } else if (firstDate && lastDate) {
                putSelectedDates({
                    'start': firstDate.format('YYYY-MM-DD'),
                    'end': lastDate.format('YYYY-MM-DD'),
                });
            } else {
                console.log('Error');
            }
        }
    }, [firstDate, lastDate]);

    useEffect(() => {
        if (cleanUp) {
            cleanUpCalendar();
            setFirstDate(null);
            setLastDate(null);
        }
    }, [cleanUp]);

    useEffect(() => {
        cleanUpCalendar();
        setFirstDate(null);
        setLastDate(null);
    }, [])

    return (
        <CalendarTemplate startDate={startDate} t={t} calendarData={calendarData} isMobile={isMobile} handleDateSelect={handleDateSelect} />
    );
}

export default CalendarPreSelection;