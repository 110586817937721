import React, {createContext, useContext, useState, useEffect} from "react";
import CartAPI from "../handlers/api-handlers/CartAPI";
import {useRequestContext} from "./RequestContext";
import {useError} from "../errors/ErrorProvide";

const CartStepperContext = createContext();

export const useCartStepperContext = () => useContext(CartStepperContext);

export const CartStepperProvider = ({children}) => {

    const {
        isInRequestState, setIsInRequestState, setUnsetRequest, setRequestData, setRequestUrl
    } = useRequestContext();
    const { showError } = useError();

    const steps = {
        'default': ['cart.stepper.cart', 'cart.stepper.address', 'cart.stepper.payment', 'cart.stepper.confirmation'],
        'request': ['cart.stepper.cart', 'cart.stepper.address', 'cart.stepper.request', 'cart.stepper.payment', 'cart.stepper.confirmation'],
    };

    const [activeStepper, setActiveStepper] = useState(null);

    const [activeStep, setActiveStep] = useState(0);
    const [cartType, setCartType] = useState(null);
    const [canUseNext, setCanUseNext] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const cartAPI = new CartAPI();

    // ----- API CALLS -----

    const getCartType = async (initCartType = null) => {
        if (initCartType === null) {
            const [success, response] = await cartAPI.getCartType();
            if (success) {
                if (response.data !== undefined && response.data.cart_type !== undefined) {
                    setCartType(response.data.cart_type);
                    setUnsetRequest(response.data.cart_type);
                } else {
                    showError("Network error. Please try again later.");
                }
            } else {
                showError(response);
            }
        }
    }

    const handleAPICall = async () => {
        setIsLoading(true);
        try{
            if (activeStep === 0) {
                // call API for storing the cart into the database
                return true;
            } else if (activeStep === 1) {
                if (address.fname === '' || address.sname === '' || address.street === '' || address.housenr === '' || address.plz === '' || address.city === '' || address.country === '' || address.phone01 === '' || address.email01 === '') {
                    showError("Please fill out all fields.");
                    return false;
                }
                // call API for storing the address to the cart order

                const [success, response] = await cartAPI.addressCheckout(address);
                if (!success) {
                    showError(response);
                    return false;
                } else {
                    if (response.request_data !== undefined && response.request_data !== null) {
                        setRequestData(response.request_data);
                    }
                    if (response.url !== undefined && response.url !== null) {
                        setRequestUrl(response.url);
                        window.open(response.url, '_blank');
                    }
                }
                return true;
            } else if (activeStep === 2) {
                // call API for checking the payment
                return true;
            } else {
                return false;
            }
        } finally {
            setIsLoading(false);
        }
        return false;
    }

    // ----- END API CALLS -----

    // ----- ADDRESS -----

    const requiredAddressFields = ['fname', 'sname', 'street', 'house_number', 'plz', 'city', 'country', 'phone01', 'email01', 'terms', 'privacy'];
    const [address, setAddress] = React.useState({
        'fname': '',
        'sname': '',
        'street': '',
        'house_number': '',
        'plz': '',
        'city': '',
        'country': '',
        'phone01': '',
        'phone02': '',
        'email01': '',
        'email02': '',
        'note': '',
        'country-others': false,
        'country-other': '',
        'newsletter': false,
        'terms': false,
        'privacy': false,
    });

    const handleAddressChange = (e) => {
        if (e.target.type === 'checkbox') {
            setAddress({
                ...address,
                [e.target.name]: e.target.checked,
            });
            return;
        }

        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        sessionStorage.setItem('address', JSON.stringify(address));
    }, [address]);

    const loadAddress = () => {
        if (sessionStorage.getItem('address') !== null) {
            setAddress(JSON.parse(sessionStorage.getItem('address')));
        }
    }

    // ----- END ADDRESS -----

    // ----- STEPPER ------

    const defaultStepNextCheck = (nextStep) => {
        console.error(nextStep, 'default');
    }

    const requestStepNextCheck = (nextStep) => {
        if (nextStep === 1) {
            setCanUseNext(true);
        }
    }

    const checkPaymentAllowance = (nextStep) => {
        if (cartType !== null) {
            if (cartType === 'default') {
                defaultStepNextCheck(nextStep);
            } else if (cartType === 'request') {
                requestStepNextCheck(nextStep);
            } else {
                setCanUseNext(false);
            }
        } else {
            setCanUseNext(false);
        }
    }

    const checkIsOnPaymentPage = (nextStep) => {
        // console.log(cartType, nextStep);
        // checkPaymentAllowance(nextStep);
        // if (cartType !== null) {
        //     // console.log('Cart type not null')
        //     if (cartType === 'default' && nextStep === 2) {
        //         // console.log('is default and next is 2, nextStep:', nextStep)
        //         setCanUseNext(false);
        //     } else if (cartType === 'request' && nextStep === 3) {
        //         // console.log('is request and next is 3, nextStep: ', nextStep)
        //         setCanUseNext(false);
        //         setIsInRequestState(true);
        //     } else {
        //         // console.log('is any other', cartType, nextStep)
        //         setCanUseNext(true);
        //     }
        // } else {
        //     setCanUseNext(true);
        // }
    }

    const handleNext = async () => {
        const success = await handleAPICall();

        if (!success) {
            return;
        }

        let nextStep = activeStep + 1;
        setActiveStep(nextStep);
        checkIsOnPaymentPage(nextStep);
    }

    const handleBack = () => {
        let nextStep = activeStep - 1;
        setActiveStep(nextStep);
        checkIsOnPaymentPage(nextStep);
    }

    // ----- END STEPPER ------

    // ----- CART TYPE ------

    useEffect(() => {
        if (cartType !== null) {
            try{
                const newActiveStepper = steps[cartType];
                setActiveStepper(newActiveStepper);
            } catch (e) {
                console.error('Cart type not found');
                return;
            }
        }
    }, [cartType]);

    React.useEffect(() => {
        if (cartType !== null) {
            checkIsOnPaymentPage(activeStep);
        }
    }, [activeStep, cartType]);

    // ----- END CART TYPE ------


    return (
        <CartStepperContext.Provider
            value={{
                // Loading
                isLoading,
                setIsLoading,

                // Stepper
                activeStep,
                setActiveStep,
                steps,
                activeStepper,
                handleNext,
                handleBack,

                // Cart Type
                setCartType,
                canUseNext,
                setCanUseNext,
                cartType,

                // Address
                handleAddressChange,
                address,
                loadAddress,
                requiredAddressFields,

                // API
                handleAPICall,
                getCartType,
            }}>
            {children}
        </CartStepperContext.Provider>
    );
}