import React, {useEffect, useState} from 'react';
import {Box, Card, CircularProgress, Typography} from "@mui/material";
import PaymentAPI from "../../../../../common/handlers/api-handlers/PaymentAPI";
import PayPalCheckoutForm from "./paymentTypes/paypal/PayPalCheckoutForm";
import StripeCheckout from "./paymentTypes/stripe/StripeCheckout";
import HobexCheckoutForm from "./paymentTypes/hobex/HobexCheckoutForm";
import {useCartStepperContext} from "../../../../../common/providers/CartStepperContext";
import {useTranslation} from "react-i18next";
import CartButtons from "../CartButtons";

function PaymentSelection({ paymentMethods, paymentPossible=false}) {

    const { handleNext, setCanUseNext } = useCartStepperContext();
    const { t } = useTranslation();

    const [paymentType, setPaymentType] = useState(null);
    const API = new PaymentAPI();

    const { address } = useCartStepperContext();

    const [fetchedPaymentType, setFetchedPaymentType] = useState(false);

    const fetchPaymentType = async () => {
        await API.getPaymentType().then(async ([success, response]) => {
            if (!success) {
                return;
            }
            setFetchedPaymentType(true);


            await new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 200);
            })

            setPaymentType(response.payment_type);
        });
    }

    useEffect(() => {
        // console.log('fetching payment type');
        setCanUseNext(false);
        fetchPaymentType();

    }, []);


    if (!fetchedPaymentType || !paymentType) {
        return (
            <Box>
                <CircularProgress />
            </Box>
        );
    } else {
        if (paymentType === "stripe") {
            return (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                            padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                            margin: 'auto'
                        }}
                    >
                        <StripeCheckout address={address} />
                    </Box>
                    <CartButtons />
                </>
            );
        } else if (paymentType === "paypal") {
            return (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                            padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                            margin: 'auto'
                        }}
                    >
                        <PayPalCheckoutForm handleStepCompleted={handleNext}/>
                    </Box>
                    <CartButtons />
                </>
            );
        } else if (paymentType === "hobex") {
            return (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                            padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                            margin: 'auto'
                        }}
                    >
                        <HobexCheckoutForm />
                    </Box>
                    <CartButtons />
                </>
            )
        } else {
            return (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                            padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                            margin: 'auto'
                        }}
                    >
                        <Card variant={"outlined"}>
                            <Typography variant={"h6"}>{t("cart.payment.title")}</Typography>
                            <Typography variant={"body1"}>{t("cart.payment.not-possible")}</Typography>
                        </Card>
                    </Box>
                    <CartButtons />
                </>
            );
        }
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                    padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                    margin: 'auto'
                }}
            >
                <Card variant={"outlined"}>
                    <Typography variant={"h6"}>{t("cart.payment.title")}</Typography>
                    {
                        paymentPossible ? (
                            <Typography variant={"body1"}>{t("cart.payment.possible")}</Typography>
                        ) : (
                            <Typography variant={"body1"}>{t("cart.payment.not-possible")}</Typography>
                        )
                    }
                </Card>
            </Box>
            <CartButtons />
        </>
    );
}

export default PaymentSelection;