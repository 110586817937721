import React, {useEffect, useState} from 'react';
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";
import {useMobileViewContext} from "../../../components/templates/simpleAssistant/globals/MobileViewContext";
import {AddCircle} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import InputField from "../inputFields/InputField";

const initBoxSx = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    '&.MuiTextField-root': {
        maxWidth: '250px'
    }
}

const initFormStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    height: '100%',
    width: '100%'
}


const initIconBtnSize = "medium"

function AddNewPerson({
    open,
    handleClose,
    addFormData,
    addFormFields,
    handleAddPersonChange,
    handleBirthAddPersonChange,
    handleAddPerson
                      }) {
    /**
     * This component is a Dialog which allows the user to add a new person to the list of persons.
     */
    const {viewType} = useMobileViewContext();

    const [boxSx, setBoxSx] = useState(initBoxSx);
    const [formStyle, setFormStyle] = useState(initFormStyle);
    const [iconBtnSize, setIconBtnSize] = useState(initIconBtnSize);
    const { t} = useTranslation();

    const handlePressEnter = (e) => {
        if (e.key === 'Enter') {
            handleAddPerson();
        }
    }

    useEffect(() => {
        let newBoxSx = {...initBoxSx};
        let newFormStyle = {...initFormStyle};
        let newCircleSize = initIconBtnSize;

        if (viewType === 'mobile' || viewType === 'tablet') {
            newBoxSx.flexDirection = 'column';
            // newBoxSx.alignItems = 'start'
            newFormStyle.flexDirection = 'column';
            // newFormStyle.alignItems = 'start'
            newCircleSize = "large";
        } else {
            newBoxSx.flexDirection = 'column';
            newFormStyle.flexDirection = 'column';
            newCircleSize = "large";
        }

        setBoxSx(newBoxSx);
        setFormStyle(newFormStyle);
        setIconBtnSize(newCircleSize);

    }, [viewType]);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t('person.create.title')}</DialogTitle>
                <DialogContent>
                    {
                        (addFormData === null || addFormData === undefined || addFormFields === null || addFormFields === undefined) ? (
                            <CircularProgress />
                        ) : (
                            <form
                                id={'sb-simple-assistant-add-person-form-dialog'}
                                style={formStyle}
                            >
                                {
                                    addFormFields.map((field, index) => {
                                        return (
                                            <InputField
                                                key={'private-person-add-' + index}
                                                field={field}
                                                value={(addFormData[field.name]) ? addFormData[field.name] : ''}
                                                t={t}
                                                isMobile={(viewType === 'mobile' || viewType === 'tablet')}
                                                currentLanguage={'de'}
                                                handleChange={(field.type !== 'date') ? handleAddPersonChange : handleBirthAddPersonChange}
                                                handlePressEnter={handlePressEnter}
                                            />
                                        )
                                    })
                                }

                                <IconButton variant={"text"} onClick={handleAddPerson}>
                                    <AddCircle fontSize={iconBtnSize} />
                                </IconButton>
                            </form>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddNewPerson;