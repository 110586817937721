import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Box, CircularProgress } from "@mui/material";
import { useMobileViewContext } from "../globals/MobileViewContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CarouselItem({ imgUrl, imgTitle }) {
    return (
        <div style={{ height: "100%", padding: "0 0" }}> {/* Temporary border for debugging */}
            <img
                src={imgUrl}
                alt={imgTitle}
                style={{ width: "100%", height: "300px", objectFit: "cover", objectPosition: "center"}}
            />
        </div>
    );
}

function SliderComponent() {
    const [cardDetails, setCardDetails] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { viewType } = useMobileViewContext();

    useEffect(() => {
        setIsMobile(viewType === 'mobile' || viewType === 'tablet');
    }, [viewType]);

    const fetchData = async () => {
        try {
            const response = await fetch('/template/carousel-config.json');
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setCardDetails(data);
            setIsLoaded(true);
        } catch (error) {
            console.error("Error fetching the carousel configuration:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (!isLoaded || !cardDetails) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                <CircularProgress />
            </Box>
        );
    }

    // Simplified slider settings for debugging
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
    };

    return (
        <div
            className="slider-container"
            style={{
                height: "300px",
                width: "100%",
                // backgroundColor: "lightgray", // Added background color for visibility
                borderTop: "3px solid lightgray", // Temporary border for debugging
                borderBottom: "3px solid lightgray", // Temporary border for debugging
                overflow: "hidden" // Prevent infinite scrolling
            }}
        >
            <Slider {...cardDetails.settings} style={{ height: "100%" }}>
                {Object.keys(cardDetails.items).map((detailKey) => (
                    <CarouselItem
                        imgUrl={cardDetails.items[detailKey].img}
                        imgTitle={cardDetails.items[detailKey].title}
                        key={detailKey}
                    />
                ))}
            </Slider>
        </div>
    );
}

export default SliderComponent;
