import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import ShopPages, { ShopRoutes } from "../../components/controller/shop/ShopPages";
import CartPages, { CartRoutes } from "../../components/controller/shop/CartPages";
// import AccountPages, { AccountRoutes } from "../../components/controller/account/AccountPages";
// import AssistantHandler from "../../components/controller/shop/AssistantHandler";

function PageHandler({ menuItems }) {
    const navigate = useNavigate();
    const location = useLocation();

    // Combine all your routes from the different components
    const allRoutes = [
        ...ShopRoutes.map(route => route.props.path),
        ...CartRoutes.map(route => route.props.path),
        // ...AccountRoutes.map(route => route.props.path),
        // Add more routes here if needed
    ];

    useEffect(() => {
        const currentPath = location.pathname;

        // Check if the current path matches any of the defined routes
        const isValidPath = allRoutes.includes(currentPath) || allRoutes.some(route => currentPath.startsWith(route));

        // If there is only one menu item and the path is invalid, redirect to that menuItem's link
        if (menuItems && menuItems.length === 1 && !isValidPath) {
            navigate(menuItems[0].link);
        }

    }, [menuItems, location, navigate, allRoutes]);

    return (
        <Routes>
            <Route path="/shop" element={<ShopPages menuItems={menuItems} />}>
                {ShopRoutes}
            </Route>
            <Route path="/cart" element={<CartPages menuItems={menuItems} />}>
                {CartRoutes}
            </Route>
            {/*<Route path="/account" element={<AccountPages menuItems={menuItems} />}>*/}
            {/*    {AccountRoutes}*/}
            {/*</Route>*/}
            {/* Add any other specific routes here */}
            {/* No root path defined */}
        </Routes>
    );
}

export default PageHandler;
