import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import {Box, Button, ButtonGroup, CircularProgress} from "@mui/material";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

export default function Footer({ footerAddStyling=null, languageSelection=[] }) {

    const { t } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        const html_tag = document.getElementsByTagName('html')[0];
        html_tag.setAttribute('lang', lng);
    }

    const [footerData, setFooterData] = React.useState(null);

    const fetchData = async () => {
        const response = await fetch('/template/footer-config.json');
        const data = await response.json();
        setFooterData(data);
    }

    React.useEffect(() => {
        fetchData();
    }, []);


    if (footerData === null) {
        return <Box>
            <CircularProgress />
        </Box>
    }


    return (
        <Box
            id={'footer'}
            component="footer"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
                py: 1.5,
                px: 3,
                mt: 2,
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            {footerData.aboutUs.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {footerData.aboutUs.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            {footerData.contact.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {footerData.contact.address}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {t('footer.email')}: <Link href={`mailto:${footerData.contact.email}`} color="inherit">{footerData.contact.email}</Link>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {t('footer.phone')}: <Link href={`tel:${footerData.contact.phone}`} color="inherit">{footerData.contact.phone}</Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            {footerData.socialMedia.title}
                        </Typography>
                        {
                            (footerData.socialMedia.facebook) ? (
                                <Link href={footerData.socialMedia.facebook} color="inherit">
                                    <Facebook/>
                                </Link>
                            ) : (<></>)
                        }
                        {
                            (footerData.socialMedia.instagram) ? (
                                <Link href={footerData.socialMedia.instagram} color="inherit" sx={{pl: 1, pr: 1}}>
                                    <Instagram/>
                                </Link>
                            ) : (<></>)
                        }
                        {
                            (footerData.socialMedia.twitter) ? (
                                <Link href={footerData.socialMedia.twitter} color="inherit">
                                    <Twitter/>
                                </Link>
                            ) : (<></>)
                        }
                        <div>
                            <ButtonGroup
                                color='info'
                                orientation="horizontal"
                                size="medium"
                                variant="text"
                                sx={{

                                }}
                            >
                                {
                                    languageSelection.map((language) => {
                                        return (
                                            <Button key={language} onClick={() => changeLanguage(language)}>{language.toUpperCase()}</Button>
                                        )
                                    })
                                }
                                {/*<Button onClick={() => changeLanguage('en')}>EN</Button>*/}
                                {/*<Button onClick={() => changeLanguage('de')}>DE</Button>*/}
                            </ButtonGroup>
                        </div>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2" color="text.secondary" align="center">
                    {"Copyright © "}
                        <Link color="inherit" href="https://komm-it.info/">
                            FunkeSolution GmbH
                        </Link>{" "}
                        {new Date().getFullYear()}
                        {"."}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                        <Link color={'inherit'} href={process.env.REACT_APP_IMPRESSUM}>{t('footer.imprint')}</Link>
                        {" | "}
                        <Link color={'inherit'} href={process.env.REACT_APP_DATENSCHUTZ}>{t('footer.privacy')}</Link>
                        {" | "}
                        <Link color={'inherit'} href={process.env.REACT_APP_AGB}>{t('footer.terms')}</Link>
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}