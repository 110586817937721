import React, {useContext, createContext, useState} from 'react';

const MobileViewContext = createContext();

export const useMobileViewContext = () => useContext(MobileViewContext);

export const MobileViewProvider = ({children}) => {

    const [width, setWidth] = React.useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);
    const mobileBreakpoint = 768;
    const [isTablet, setIsTablet] = useState(false);
    const tabletBreakpoint = 1024;
    const [isDesktop, setIsDesktop] = useState(true);
    const [viewType, setViewType] = useState('desktop');

    const checkForViewType = (width) => {
        if (width < mobileBreakpoint) {
            setIsMobile(true);
            setIsTablet(false);
            setIsDesktop(false);
            setViewType('mobile');
        } else if (width < tabletBreakpoint) {
            setIsMobile(false);
            setIsTablet(true);
            setIsDesktop(false);
            setViewType('tablet');
        } else {
            setIsMobile(false);
            setIsTablet(false);
            setIsDesktop(true);
            setViewType('desktop');
        }
    }

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        if (width > 1024 && isDesktop === false) checkForViewType(width);
        if (width > 768 && width < 1024 && isTablet === false) checkForViewType(width);
        if (width < 768 && isMobile === false) checkForViewType(width);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [window.innerWidth, isDesktop, isTablet, isMobile, width]);

    return (
        <MobileViewContext.Provider
            value={{
                isMobile,
                isTablet,
                isDesktop,
                viewType,
                checkForViewType
            }}>
            {children}
        </MobileViewContext.Provider>
    );
}