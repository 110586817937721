import React, {createContext, useContext, useState} from "react";

const RequestContext = createContext();

export const useRequestContext = () => useContext(RequestContext);

export const RequestProvider = ({children}) => {

    const [isRequest, setIsRequest] = useState(false);
    const [allowToPay, setAllowToPay] = useState(false);
    const [isInRequestState, setIsInRequestState] = useState(false);
    const [requestData, setRequestData] = useState(null);
    const [requestUrl, setRequestUrl] = useState(null);

    const setUnsetRequest = (value) => {
        if (value === undefined || value === null) {
            setIsRequest(false);
        } else if (typeof value === 'boolean') {
            setIsRequest(value);
        } else if (typeof value === 'string') {
            if (value === 'true') {
                setIsRequest(true);
            } else if (value === 'false') {
                setIsRequest(false);
            } else if (value === 'default') {
                setIsRequest(false);
            } else if (value === 'request') {
                setIsRequest(true);
            } else {
                setIsRequest(false);
            }
        } else {
            setIsRequest(false);
        }
    }

    return (
        <RequestContext.Provider
            value={{
                isRequest,
                setUnsetRequest,
                allowToPay,
                setIsInRequestState,
                isInRequestState,
                requestData,
                setRequestData,
                requestUrl,
                setRequestUrl
            }}>
            {children}
        </RequestContext.Provider>
    );
}