import React, {useContext, createContext, useState} from 'react';

const CalendarSelectionContext = createContext();

export const useCalendarSelectionContext = () => useContext(CalendarSelectionContext);

export const CalendarSelectionProvider = ({children}) => {

    const [selectedDates, setSelectedDates] = useState(null);
    const [sessionSelectedDates, setSessionSelectedDates] = useState([]); // [startDate, endDate
    const [cleanUp, setCleanUp] = useState(false);

    const putSelectedDates = (dates=null) => {
        setSelectedDates(dates);
    }

    const getSelectedDates = () => {
        return selectedDates;
    }

    React.useEffect(() => {
        if (cleanUp) {
            setSelectedDates(null);
            setCleanUp(false);
        }
    }, [cleanUp])

    return (
        <CalendarSelectionContext.Provider
            value={{
                putSelectedDates,
                selectedDates,
                setCleanUp,
                cleanUp
            }}>
            {children}
        </CalendarSelectionContext.Provider>
    );
}