import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, IconButton, Tooltip, Zoom} from "@mui/material";
import {AddCircle, Delete} from "@mui/icons-material";
import {usePersonContext} from "../globals/PersonContext";
import 'dayjs/locale/de';
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {useMobileViewContext} from "../globals/MobileViewContext";
import InputField from "../../../../common/components/inputFields/InputField";


const initBoxSx = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    mt: 2,
    '&.MuiTextField-root': {
        maxWidth: '250px'
    }
}

const initFormStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 3,
    mt: 2
}

const initIconBtnSize = "medium"


const sxDefaultsBtnsAfterAddPersonData = {
    btnFontSize: '1.9rem',
    initIconBtnSize: 'inherit',
    mobileIconBtnSize: 'large',
    deleteIconColor: '#888888',
    backgroundColor: '#64b939',
    color: '#fff',
    transition: 'all 0.3s ease-in-out',
    hover: {
        color: '#cbcbcb',
        backgroundColor: '#266409',
        deleteIconColor: '#696969'
    }
}

const defaultSxTooltip = {
    backgroundColor: sxDefaultsBtnsAfterAddPersonData.backgroundColor,
    color: sxDefaultsBtnsAfterAddPersonData.color,
    '& .MuiTooltip-arrow': {
        color: sxDefaultsBtnsAfterAddPersonData.backgroundColor
    }
}

const defaultFormData = {
    'initFormData': {
        'fname': '',
        'sname': '',
        'height': '',
        'birthdate': null
    },
    'initFormFields': [
        {
            'name': 'fname',
            'label': 'simple-assistant.fname',
            'type': 'text',
            'tooltip': 'simple-assistant.fname-tooltip',
            'required': true,
            'size': 'small',
            'variant': 'standard'
        },
        {
            'name': 'sname',
            'label': 'simple-assistant.sname',
            'type': 'text',
            'tooltip': 'simple-assistant.sname-tooltip',
            'required': true,
            'size': 'small',
            'variant': 'standard'
        },
        {
            'name': 'height',
            'label': 'simple-assistant.height',
            'type': 'number',
            'tooltip': 'simple-assistant.height-tooltip',
            'required': true,
            'size': 'small',
            'variant': 'standard'
        },
        {
            'name': 'birthdate',
            'label': 'simple-assistant.birth',
            'type': 'date',
            'tooltip': 'simple-assistant.birth-tooltip',
            'date-format': 'DD.MM.YYYY',
            'required': true,
            'variant': 'standard',
            'size': 'small',
            'disableFuture': true,
            'disablePast': false,
            'sx': {
                maxWidth: '200px'
            },
            'slotProps': {
                textField: {
                    size: 'small',
                    variant: 'standard',
                }
            }
        }
    ],
    "form": [
        {
            "id": "f7e8ff17-0ddd-42b8-92fd-47b1277949cc",
            "label": "simple-assistant.fname",
            "name": "fname",
            "required": false,
            "size": "small",
            "sx": null,
            "tooltip": "simple-assistant.fname-tooltip",
            "translation_key": "simple-assistant",
            "type": "text",
            "variant": "standard"
        },
        {
            "id": "9d6c50ae-7673-45ea-97f1-af76703beb52",
            "label": "simple-assistant.sname",
            "name": "sname",
            "required": false,
            "size": "small",
            "sx": null,
            "tooltip": "simple-assistant.sname-tooltip",
            "translation_key": "simple-assistant",
            "type": "text",
            "variant": "standard"
        },
        {
            "id": "85697944-6f8b-4f07-8415-2161ac527d65",
            "label": "simple-assistant.levels",
            "multiple": false,
            "name": "levels",
            "options": [
                {
                    "disabled": false,
                    "id": "1000",
                    "label": "A",
                    "translations": {
                        "de": {
                            "description": "NaN",
                            "name": "Leihartikel Sommer 1 frei"
                        },
                        "en": {
                            "description": "NaN",
                            "name": "Rent article summer 1 free"
                        }
                    },
                    "value": 1000
                },
                {
                    "disabled": false,
                    "id": "1001",
                    "label": "A/B",
                    "translations": {
                        "de": {
                            "description": "NaN",
                            "name": "A/B"
                        },
                        "en": {
                            "description": "NaN",
                            "name": "A/B"
                        }
                    },
                    "value": 1001
                },
                {
                    "disabled": false,
                    "id": "1002",
                    "label": "B",
                    "translations": {
                        "de": {
                            "description": "NaN",
                            "name": "B"
                        },
                        "en": {
                            "description": "NaN",
                            "name": "B"
                        }
                    },
                    "value": 1002
                },
                {
                    "disabled": false,
                    "id": "1003",
                    "label": "B/C",
                    "translations": {
                        "de": {
                            "description": "NaN",
                            "name": "B/C"
                        },
                        "en": {
                            "description": "NaN",
                            "name": "B/C"
                        }
                    },
                    "value": 1003
                },
                {
                    "disabled": false,
                    "id": "1004",
                    "label": "C",
                    "translations": {
                        "de": {
                            "description": "NaN",
                            "name": "C"
                        },
                        "en": {
                            "description": "NaN",
                            "name": "C"
                        }
                    },
                    "value": 1004
                },
                {
                    "disabled": false,
                    "id": "1005",
                    "label": "C/D",
                    "translations": {
                        "de": {
                            "description": "NaN",
                            "name": "C/D"
                        },
                        "en": {
                            "description": "NaN",
                            "name": "C/D"
                        }
                    },
                    "value": 1005
                },
                {
                    "disabled": false,
                    "id": "1006",
                    "label": "D",
                    "translations": {
                        "de": {
                            "description": "NaN",
                            "name": "D"
                        },
                        "en": {
                            "description": "NaN",
                            "name": "D"
                        }
                    },
                    "value": 1006
                },
                {
                    "disabled": false,
                    "id": "1007",
                    "label": "E",
                    "translations": {
                        "de": {
                            "description": "NaN",
                            "name": "E"
                        },
                        "en": {
                            "description": "NaN",
                            "name": "E"
                        }
                    },
                    "value": 1007
                }
            ],
            "required": false,
            "size": "small",
            "slot_props": null,
            "sx": null,
            "tooltip": "simple-assistant.levels-tooltip",
            "translation_key": "simple-assistant",
            "type": "select",
            "variant": "standard"
        },
        {
            "date_format": "DD.MM.YYYY",
            "disable_future": true,
            "disable_past": false,
            "disabled": false,
            "id": "e85c1fa9-36c7-4a6b-95b7-113bc28d6c47",
            "label": "simple-assistant.birthdate",
            "name": "birthdate",
            "required": false,
            "size": "small",
            "slot_props": {
                "textField": {
                    "size": 'small',
                    "variant": 'standard',
                }
            },
            "sx": null,
            "tooltip": "simple-assistant.birthdate-tooltip",
            "translation_key": "simple-assistant",
            "type": "date",
            "variant": "standard"
        }
    ]
}

function PersonHeadComponent(props) {

    const [boxSx, setBoxSx] = useState(initBoxSx);
    const [formStyle, setFormStyle] = useState(initFormStyle);
    const [iconBtnSize, setIconBtnSize] = useState(initIconBtnSize);

    const [persons, setPersons] = useState(props.persons);
    const [addFormData, setAddFormData] = useState(null);
    const { addPerson, removePerson, personFormData, personForm } = usePersonContext();
    const { t, i18n} = useTranslation();
    const {viewType} = useMobileViewContext();
    const numValues = ['height'];
    const [currentLanguage, setCurrentLanguage] = useState('de');

    const [addTooltipOpen, setAddTooltipOpen] = useState(false);

    useEffect(() => {
        setCurrentLanguage(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        setPersons(props.persons);
    }, [props.persons]);

    useEffect(() => {
        setAddFormData(personFormData);
    }, [personFormData]);

    useEffect(() => {
        let newBoxSx = {...initBoxSx};
        let newFormStyle = {...initFormStyle};
        let newCircleSize = initIconBtnSize;

        if (viewType === 'mobile' || viewType === 'tablet') {
            newBoxSx.flexDirection = 'column';
            // newBoxSx.alignItems = 'start'
            newFormStyle.flexDirection = 'column';
            newFormStyle.gap = 2;
            // newFormStyle.alignItems = 'start'
            newCircleSize = "large";
        }

        setBoxSx(newBoxSx);
        setFormStyle(newFormStyle);
        setIconBtnSize(newCircleSize);

    }, [viewType]);

    useEffect(() => {
        const showTooltip = noPersonAddedShowAlwaysTooltip(true);
        setAddTooltipOpen(showTooltip);
    }, [persons])

    const noPersonAddedShowAlwaysTooltip = (personChange = false) => {
        if (persons.length === 0) {
            return true;
        } else {
            return !addTooltipOpen;
        }
    }

    const toggleTooltipOpen = () => {
        setAddTooltipOpen(noPersonAddedShowAlwaysTooltip());
    }

    const cleanUpForm = () => {
        let emptyFormData = {};
        Object.keys(personFormData).forEach((key) => {
            if (personForm) {
                let field = personForm.find((f) => f.name === key);
                if (field) {
                    if (field.type === 'date') {
                        emptyFormData[key] = null;
                    } else {
                        emptyFormData[key] = '';
                    }
                }
            } else {
                if (key !== 'birthdate' && key !== 'birth') {
                    emptyFormData[key] = '';
                } else {
                    emptyFormData[key] = null;
                }
            }
        });
        setAddFormData(emptyFormData);
    }

    const handleAddPerson = async () => {
        // get form sb-simple-assistant-add-person-form
        const reject_values = ['', 'Invalid Date', undefined, null, NaN];
        let all_correct = [];
        Object.keys(addFormData).forEach((key) => {
            if (!addFormData[key] && reject_values.includes(addFormData[key])) {
                all_correct.push(false);
            }
        });

        if (all_correct.length > 0) {
            alert(t("simple-assistant.errors.notAllFieldsFilled"));
            return;
        }

        let last_add = await addPerson(addFormData);
        if (last_add !== null) {
            cleanUpForm();
        }
    }

    const handleAddPersonChange = (e) => {

        let value = e.target.value;
        let name = e.target.name;

        if (numValues.includes(e.target.name)) {
            value = e.target.valueAsNumber;
        }

        setAddFormData({
            ...addFormData,
            [name]: value
        });
    }

    const handleDateAddPersonChange = (newValue, fieldName) => {
        let formattedValue = null;
        try {
            formattedValue = newValue.format("YYYY-MM-DD");
        } catch (e) {
            return;
        }
        handleAddPersonChange({
            target: {
                name: fieldName,
                value: formattedValue,
            }
        });
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddPerson();
        }
    }

    const handleRemovePerson = (personId) => {
        removePerson(personId);
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'left',
                width: '100%',
                height: '100%',
            }}
        >
            {
                persons.map((person, index) => {
                    const date_value = new dayjs(person.birthdate);
                    return (
                        <Box
                            key={index}
                            sx={boxSx}
                        >
                            <div style={{textAlign: 'center', alignContent: 'center', width: '40px'}}>{index + 1}.</div>
                            {
                                (personForm !== null) ?
                                    (
                                        personForm.map((field, index) => {
                                            let newField = {...field};
                                            newField.disable = true;
                                            return (
                                                <InputField
                                                    key={'person-add-' + index}
                                                    field={newField}
                                                    value={(person[field.name]) ? person[field.name] : ''}
                                                    t={t}
                                                    isMobile={(viewType === 'mobile' || viewType === 'tablet')}
                                                    currentLanguage={currentLanguage}
                                                    handleChange={(field.type !== 'date') ? handleAddPersonChange : handleDateAddPersonChange}
                                                />
                                            )
                                        })
                                    )
                                    :
                                    (
                                        <CircularProgress />
                                    )
                            }
                            <IconButton
                                onClick={() => handleRemovePerson(person.id)}
                                sx={{
                                    m: 0,
                                    p: 0.5,
                                    cursor: 'pointer',
                                }}
                            >
                                <Delete
                                    fontSize={iconBtnSize}
                                    sx={{
                                        fontSize: sxDefaultsBtnsAfterAddPersonData.btnFontSize,
                                        transition: sxDefaultsBtnsAfterAddPersonData.transition,
                                        color: sxDefaultsBtnsAfterAddPersonData.deleteIconColor,
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: sxDefaultsBtnsAfterAddPersonData.hover.deleteIconColor,
                                            cursor: 'pointer',
                                        }
                                    }}
                                />
                            </IconButton>
                            {/*<TextField variant={"standard"} size={"small"} label={t("simple-assistant.fname")} name={"fname"} value={person.fname} onChange={handleAddPersonChange} disabled />*/}
                            {/*<TextField variant={"standard"} size={"small"} label={t("simple-assistant.sname")} name={"sname"} value={person.sname} onChange={handleAddPersonChange} disabled />*/}
                            {/*<TextField type={"number"} variant={"standard"} size={"small"} label={t("simple-assistant.height")} name={"height"} value={person.height} onChange={handleAddPersonChange} disabled />*/}
                            {/*<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">*/}
                            {/*    <DatePicker*/}
                            {/*        disabled*/}
                            {/*        id={"simple-assistant-add-person-birth"}*/}
                            {/*        value={date_value}*/}
                            {/*        name={"birthdate"}*/}
                            {/*        label={t("simple-assistant.birth")}*/}
                            {/*        format={"DD.MM.YYYY"}*/}
                            {/*        // disableFuture={true}*/}
                            {/*        // disablePast={false}*/}
                            {/*        onChange={handleDateAddPersonChange}*/}
                            {/*        onAccept={handleDateAddPersonChange}*/}
                            {/*        sx={{*/}
                            {/*            maxWidth: '200px',*/}
                            {/*        }}*/}
                            {/*        slotProps={{ textField: { size: 'small', variant: 'standard' } }}*/}
                            {/*    />*/}
                            {/*</LocalizationProvider>*/}
                        </Box>
                    );
                })
            }
            <Box
                sx={{
                    mt: 2
                }}
            >
                {/*<form
                    id={'sb-simple-assistant-add-person-form'}
                    style={formStyle}
                >
                    <div style={{textAlign: 'center', alignContent: 'center', width: '40px'}}>{persons.length + 1}.</div>
                    <TextField
                        value={addFormData.fname}
                        variant={"standard"}
                        size={"small"}
                        label={t("simple-assistant.fname")}
                        name={"fname"}
                        onChange={handleAddPersonChange}
                        onKeyDown={(e) => handleEnter(e)}
                    />
                    <TextField
                        value={addFormData.sname}
                        id={"simple-assistant-add-person-sname"}
                        variant={"standard"}
                        size={"small"}
                        label={t("simple-assistant.sname")}
                        name={"sname"}
                        onChange={handleAddPersonChange}
                        onKeyDown={(e) => handleEnter(e)}

                    />
                    <TextField
                        type={"number"}
                        value={addFormData.height}
                        variant={"standard"}
                        size={"small"}
                        label={t("simple-assistant.height")}
                        name={"height"}
                        onChange={handleAddPersonChange}
                        onKeyDown={(e) => handleEnter(e)}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <DatePicker
                            id={"simple-assistant-add-person-birth"}
                            value={addFormData.birthdate}
                            name={"birthdate"}
                            label={t("simple-assistant.birth")}
                            format={"DD.MM.YYYY"}
                            disableFuture={true}
                            disablePast={false}
                            onChange={handleDateAddPersonChange}
                            onAccept={handleDateAddPersonChange}
                            sx={{
                                maxWidth: '200px',
                            }}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    variant: 'standard',
                                    onKeyDown: (e) => handleEnter(e),
                                }
                            }}
                        />
                    </LocalizationProvider>
                    <IconButton variant={"text"} onClick={handleAddPerson}>
                        <AddCircle fontSize={iconBtnSize} />
                    </IconButton>
                </form>*/}
            </Box>
            {
                (addFormData !== null && personForm !== null) ?
                    (
                        <Box
                            sx={{
                                mt: 2
                            }}
                        >
                            <form id={'sb-simple-assistant-add-person-form2'} style={formStyle}>
                                <div style={{textAlign: 'center', alignContent: 'center', width: '40px'}}>{persons.length + 1}.</div>
                                {
                                    personForm.map((field, index) => {
                                        return (
                                            <InputField
                                                key={'person-add-' + index}
                                                field={field}
                                                value={addFormData[field.name]}
                                                t={t}
                                                isMobile={(viewType === 'mobile' || viewType === 'tablet')}
                                                currentLanguage={currentLanguage}
                                                handleChange={(field.type !== 'date') ? handleAddPersonChange : handleDateAddPersonChange}
                                            />
                                        )
                                    })
                                }
                                <Tooltip
                                    open={addTooltipOpen}
                                    onClose={toggleTooltipOpen}
                                    onOpen={toggleTooltipOpen}
                                    title={t("simple-assistant.add-person")}
                                    placement={"bottom"}
                                    TransitionComponent={Zoom}
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                ...defaultSxTooltip,
                                                fontSize: '0.9rem'
                                            }
                                        }
                                    }}
                                    arrow>
                                    <IconButton
                                        onClick={handleAddPerson}
                                        sx={{
                                            m: 0,
                                            p: 0.5,
                                            cursor: 'pointer',
                                        }}
                                        >
                                        <AddCircle
                                            fontSize={sxDefaultsBtnsAfterAddPersonData.initIconBtnSize}
                                            // style={{ color: colorSxTooltip.backgroundColor}}
                                            sx={{
                                                fontSize: sxDefaultsBtnsAfterAddPersonData.btnFontSize,
                                                color: sxDefaultsBtnsAfterAddPersonData.backgroundColor,
                                                cursor: 'pointer',
                                                transition: sxDefaultsBtnsAfterAddPersonData.transition,
                                                '&:hover': {
                                                    color: sxDefaultsBtnsAfterAddPersonData.hover.backgroundColor,
                                                    cursor: 'pointer',
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </form>
                        </Box>
                    )
                    :
                    null
            }

        </Box>
    );
}

export default PersonHeadComponent;