import React from 'react';
import CalendarSelfSelection from "../../../calendar/CalendarSelfSelection";
import CalendarTemplate from "../../../calendar/CalendarTemplate";
import {useTranslation} from "react-i18next";
import CalendarPreSelection from "../../../calendar/CalendarPreSelection";

function ProductCalendar({calendarBooking, isMobile}) {

    const {t} = useTranslation();
    if (calendarBooking.selectionType === 'selfSelection') {
        return (
            <CalendarSelfSelection startDate={calendarBooking.startDate} t={t} calendarData={calendarBooking} isMobile={isMobile} />
        )
    } else if (calendarBooking.selectionType === 'preSelection') {
        return (
            <CalendarPreSelection startDate={calendarBooking.startDate} t={t} calendarData={calendarBooking} isMobile={isMobile} />
        );
    } else {
        return (
            <CalendarTemplate startDate={'2024-02-15'} t={t} calendarData={calendarBooking} isMobile={isMobile} />
        );
    }
}

export default ProductCalendar;