import React, {useEffect} from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup, Select, SvgIcon,
    TextField, Tooltip
} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/de';
import {HelpOutline} from "@mui/icons-material";
import dayjs from "dayjs";


function InputFieldRadio({ field, t, isMobile, currentLanguage, handleChange, error, errorMsg, errorTranslation }) {

    // ignore orientation for now
    // TODO: add orientation support

    // const [checked, setChecked] = React.useState({})
    //
    // useEffect(() => {
    //     let checkedState = {};
    //     field.options.forEach((option) => {
    //         checkedState[option.id] = false;
    //     });
    //     setChecked(checkedState);
    //
    // }, []);

    return (
        <>
            <FormLabel>{field.label[currentLanguage]} {(field.required) ? '*' : ''}</FormLabel>
            <RadioGroup
                aria-orientation={field["option-settings"].orientation}
                name={field.id}
            >
                {
                    field.options.map((option, index) => {
                        return (
                            <FormControlLabel
                                name={field.id}
                                control={<Radio />}
                                label={option.label[currentLanguage]}
                                value={option.id}
                                onChange={handleChange}
                                key={'radio-option-' + index}
                                disabled={field.disable}
                            />
                        )
                    })
                }
            </RadioGroup>
            {(error)
                ?
                <>
                    <FormHelperText error>{t(`fields.errors.${errorTranslation}`)}</FormHelperText>
                    <FormHelperText error>{errorMsg}</FormHelperText>
                </>
                : null}
        </>
    );
}


function InputFieldText({ field, value, t, isMobile, currentLanguage, handleChange, error, errorMsg, errorTranslation, handlePressEnter }) {
    return (
        <TextField
            id={(field.id) ? field.id : field.name}
            name={field.name}
            value={value}
            size={field.size}
            label={t(field.label) + ' ' + ((field.required) ? '*' : '')}
            // placeholder={t(field.tooltip)}
            onChange={handleChange}
            variant={(field.variant) ? field.variant : 'standard'}
            sx={field.sx}
            disabled={field.disable}
            onKeyDown={(handlePressEnter !== null) ? (e) => handlePressEnter(e) : null}
        />
    );
}


function InputFieldTextarea({ field, t, isMobile, currentLanguage, handleChange, error, errorMsg, errorTranslation }) {
    return (
        <>
            <TextField
                id={"multiline-textarea-" + field.name}
                name={field.name}
                label={field.label[currentLanguage] + ' ' + ((field.required) ? '*' : '')}
                multiline
                rows={field.rows}
                // placeholder={field.tooltip[currentLanguage]}
                sx={field.sx}
                onChange={handleChange}
                disabled={field.disable}
            />
        </>
    );
}


function InputFieldSelect({ field, value, t, isMobile, currentLanguage, handleChange, error, errorMsg, errorTranslation, handlePressEnter }) {

    const [helper_txt, setHelperTxt] = React.useState('');

    useEffect(() => {
        let h_txt = '';
        h_txt += t(field.label) + '\n'
        if (field.options !== null && field.options !== undefined && field.options.length > 0) {
            field.options.map((option, idx) => {
                if (option.translations !== null && option.translations !== undefined && option.translations.length !== 0) {
                    if (option.translations[currentLanguage] !== undefined &&
                        option.translations[currentLanguage] !== null &&
                        option.translations[currentLanguage].description !== undefined &&
                        option.translations[currentLanguage].description !== null &&
                        option.translations[currentLanguage].name !== undefined &&
                        option.translations[currentLanguage].name !== null
                    ) {

                        h_txt += '\n' + option.translations[currentLanguage].name + '\n\t' + option.translations[currentLanguage].description + '\n'

                    }
                }
            });
        }

        setHelperTxt(h_txt);
    }, [currentLanguage]);

    return (
        <>
            <FormControl
                sx={{
                    color: 'black',
                    width: '100%',
                    maxWidth: '200px',
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <InputLabel id={`dynamic-select-field-${field.id}`}>{t(field.label) + ' ' + ((field.required) ? '*' : '')}</InputLabel>
                <Select
                    labelId={`dynamic-select-field-${field.id}`}
                    label={t(field.label) + ' ' + ((field.required) ? '*' : '')}
                    onChange={handleChange}
                    value={
                        field.options && field.options.some((option) => option.value === value)
                            ? value
                            : 0
                    }
                    name={field.name}
                    variant={field.variant}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        ...field.sx
                    }}
                    disabled={field.disable}
                    size={field.size}
                    onKeyDown={(handlePressEnter !== null) ? (e) => handlePressEnter(e) : null}
                    renderValue={(selectedValue) => {
                        if (!selectedValue || selectedValue === 0) {
                            return (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Tooltip
                                        placement="right-end"
                                        title={<span style={{ whiteSpace: 'pre-line' }}>{helper_txt}</span>}
                                    >
                                        <SvgIcon>
                                            <HelpOutline sx={{ m: 0, p: 0 }} />
                                        </SvgIcon>
                                    </Tooltip>
                                    <Box>{t('Bitte wählen')}</Box>
                                </Box>
                            );
                        }

                        const selectedOption = field.options.find((option) => option.value.toString() === selectedValue.toString());
                        let option_name = selectedOption ? selectedOption.label : '';

                        if (selectedOption && selectedOption.translations && selectedOption.translations[currentLanguage]) {
                            option_name = selectedOption.translations[currentLanguage].name || option_name;
                        }

                        return (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Tooltip
                                    placement="right-end"
                                    title={<span style={{ whiteSpace: 'pre-line' }}>{helper_txt}</span>}
                                >
                                    <SvgIcon>
                                        <HelpOutline sx={{ m: 0, p: 0 }} />
                                    </SvgIcon>
                                </Tooltip>
                                <Box>{option_name}</Box>
                            </Box>
                        );
                    }}
                    >
                    <MenuItem value={0}>Bitte wählen</MenuItem>
                    {
                        field.options.map((option, idx) => {

                            let option_name = field.label;

                            if (option.translations !== null && option.translations !== undefined && option.translations.length !== 0) {
                                if (option.translations[currentLanguage] !== undefined &&
                                    option.translations[currentLanguage] !== null &&
                                    option.translations[currentLanguage].name !== undefined &&
                                    option.translations[currentLanguage].name !== null) {
                                    option_name = option.translations[currentLanguage].name
                                }
                            }
                            return (
                                <MenuItem value={option.value} key={`dynamic-select-field-${field.id}-${option.value}`}>{option_name}</MenuItem>
                            )
                        })
                    }
                </Select>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                </Box>
            </FormControl>
        </>
    );
}


function InputFieldCheckbox({ field, t, isMobile, currentLanguage, handleChange, error, errorMsg, errorTranslation, handlePressEnter }) {
    return (
        <div>
            {field.name}
        </div>
    );
}


function InputFieldDate({ field, initValue, t, isMobile, currentLanguage, handleChange, error, errorMsg, errorTranslation, handlePressEnter }) {

    const [value, setValue] = React.useState(null);
    let slotProps = field.slot_props;

    React.useEffect(() => {
        if (initValue !== null && initValue !== undefined) {
            dayjs(initValue, 'YYYY-MM-DD').locale('de');
            setValue(dayjs(initValue, 'YYYY-MM-DD'));
        } else {
            setValue(null);
        }
    }, [initValue]);

    if (handlePressEnter) {
        slotProps.textField = {
            ...slotProps.textField,
            onKeyDown: (handlePressEnter !== null) ? (e) => handlePressEnter(e) : null
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
                error={error}
                helperText={(errorMsg) ? errorMsg : null}
                name={(field.id) ? field.id : field.name}
                value={(value) ? value : null}
                label={t(field.label) + ' ' + ((field.required) ? '*' : '')}
                format={field["date-format"]}
                disableFuture={field.disableFuture}
                disablePast={field.disablePast}
                onChange={(newValue) => handleChange(newValue, field.name)}
                onAccept={(newValue) => handleChange(newValue, field.name)}
                sx={field.sx}
                slotProps={field.slot_props}
                disabled={field.disable}
            />
            {(error)
                ?
                <>
                    <FormHelperText error>{t(`fields.errors.${errorTranslation}`)}</FormHelperText>
                    <FormHelperText error>{errorMsg}</FormHelperText>
                </>
                : null}
        </LocalizationProvider>
    );
}


function InputFieldTime({ field, t, isMobile, currentLanguage, handleChange, error, errorMsg, errorTranslation, handlePressEnter }) {
    return (
        <div>
            {field.name}
        </div>
    );
}


function InputFieldNumber({ field, value, t, isMobile, currentLanguage, handleChange, error, errorMsg, errorTranslation, handlePressEnter }) {
    return (
        <>
            <TextField
                type={'number'}
                onChange={handleChange}
                variant={field.variant}
                name={field.name}
                value={value}
                size={field.size}
                label={t(field.label) + ' ' + ((field.required) ? '*' : '')}
                id="outlined-start-adornment"
                sx={field.sx}
                InputProps={field.inputProps}
                disabled={field.disable}
                onKeyDown={(handlePressEnter !== null) ? (e) => handlePressEnter(e) : null}
                error={error}
            />
            {
                (error)
                ?
                <>
                    <FormHelperText error>{errorMsg}</FormHelperText>
                </>
                : null
            }
        </>
    );
}



function InputField({ field, t, isMobile, currentLanguage, handleChange, value=null, handlePressEnter=null }) {
    // switch for field.type

    const [error, setError] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [errorTranslation, setErrorTranslation] = React.useState(null);

    useEffect(() => {
        if (field.error !== undefined && field.error !== null) {
            setError(true);
            if (field.error.message !== undefined && field.error.message !== null) {
                setErrorMsg(field.error.message);
            }
            if (field.error.error !== undefined && field.error.error !== null) {
                setErrorTranslation(field.error.error)
            }
        } else {
            setError(false);
            setErrorMsg(null);
        }
    }, [field.error]);

    switch (field.type) {
        case 'radio':
            return <InputFieldRadio field={field} value={value} t={t} isMobile={isMobile} currentLanguage={currentLanguage} handleChange={handleChange} handlePressEnter={handlePressEnter} error={error} errorMsg={errorMsg} errorTranslation={errorTranslation} />;
        case 'text':
            return <InputFieldText field={field} value={value} t={t} isMobile={isMobile} currentLanguage={currentLanguage} handleChange={handleChange} handlePressEnter={handlePressEnter} error={error} errorMsg={errorMsg} errorTranslation={errorTranslation} />;
        case 'textarea':
            return <InputFieldTextarea field={field} value={value} t={t} isMobile={isMobile} currentLanguage={currentLanguage} handleChange={handleChange} handlePressEnter={handlePressEnter} error={error} errorMsg={errorMsg} errorTranslation={errorTranslation} />;
        case 'select':
            return <InputFieldSelect field={field} value={value} t={t} isMobile={isMobile} currentLanguage={currentLanguage} handleChange={handleChange} handlePressEnter={handlePressEnter} error={error} errorMsg={errorMsg} errorTranslation={errorTranslation} />;
        case 'checkbox':
            return <InputFieldCheckbox field={field} value={value} t={t} isMobile={isMobile} currentLanguage={currentLanguage} handleChange={handleChange} handlePressEnter={handlePressEnter} error={error} errorMsg={errorMsg} errorTranslation={errorTranslation} />;
        case 'date':
            return <InputFieldDate field={field} initValue={value} t={t} isMobile={isMobile} currentLanguage={currentLanguage} handleChange={handleChange} handlePressEnter={handlePressEnter} error={error} errorMsg={errorMsg} errorTranslation={errorTranslation} />;
        case 'time':
            return <InputFieldTime field={field} value={value} t={t} isMobile={isMobile} currentLanguage={currentLanguage} handleChange={handleChange} handlePressEnter={handlePressEnter} error={error} errorMsg={errorMsg} errorTranslation={errorTranslation} />;
        case 'number':
            return <InputFieldNumber field={field} value={value} t={t} isMobile={isMobile} currentLanguage={currentLanguage} handleChange={handleChange} handlePressEnter={handlePressEnter} error={error} errorMsg={errorMsg} errorTranslation={errorTranslation} />;
        default:
            return <div>Unknown field type: {field.type}</div>;
    }
}

export default InputField;