// In ErrorComponent.js
import React, {useEffect} from 'react';
import { useError } from './ErrorProvide';
import {Alert, Box, Slide} from "@mui/material";

const ErrorComponent = React.memo(() => {
    const { error, hideError } = useError();
    const [showSlide, setShowSlide] = React.useState(false);


    useEffect(() => {
        if (error.isVisible) {
            // Show the slide in immediately when an error is set to visible
            setShowSlide(true);

            // Start a timeout to slide out the error message after 5 seconds
            const timer = setTimeout(() => {
                // Begin the slide out animation
                setShowSlide(false);

                // Wait for the slide out animation to finish before hiding the error globally
                // Adjust the delay here if your animation time is different
                const animationDuration = 500; // For example, 500ms for the slide animation
                setTimeout(() => {
                    hideError();
                }, animationDuration);

            }, 5000);

            // Cleanup function to clear the timeout if the component unmounts
            return () => clearTimeout(timer);
        }
    }, [error.isVisible, hideError]);

    if (!error.isVisible) return null;


    return (
        <Box
            sx={{
                display: 'flex',
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    position: 'fixed',
                    top: '10px',
                    width: '80%',
                    zIndex: 9999,
                }}
            >
                <Slide direction="up" in={showSlide} mountOnEnter unmountOnExit
                       style={{ transitionDelay: showSlide ? '500ms' : '0ms' }}
                >
                    <Alert
                        sx={{
                            position: 'sticky',
                            top: '10px',
                            zIndex: 9999,
                        }}
                        severity="error"
                        onClose={hideError}
                    >
                        {error.message} {(error.code) ? "," + error.code : ''}
                    </Alert>
                </Slide>
            </Box>
        </Box>
    );
});

export default ErrorComponent;
