import React from 'react';
import {Box, Card, CircularProgress, IconButton, Typography} from "@mui/material";
import {useCartContext} from "../../../../../common/providers/CartContext";
import {DeleteOutline} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import CartButtons from "../CartButtons";
import {useCartStepperContext} from "../../../../../common/providers/CartStepperContext";

function CartInventory() {

    const [cart, setCart] = React.useState(null);
    const [total, setTotal] = React.useState(0);
    const [cartCount, setCartCount] = React.useState(0);

    const { t } = useTranslation();

    const {getCart, cartItems, cartAmount, cartLoading, cartPrice, removeFromCart} = useCartContext();
    const { setCanUseNext } = useCartStepperContext();

    React.useEffect(() => {
        // fetch cart from local storage
        getCart();
        setCanUseNext(true);
    }, []);

    React.useEffect(() => {
        setCart(cartItems);
        setCartCount(cartAmount);
        setTotal(cartPrice);
    }, [cartItems, cartPrice, cartAmount]);

    const handleRemoveFromCart = ({cart_item_id, person_id}) => {
        removeFromCart({cart_item_id, person_id});
    }

    if (cartLoading || !cart) {
        return (
            <Card variant={"outlined"} sx={{p: 2}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography variant={"h6"}>{t("cart.cart")}(<CircularProgress size={15}/>)</Typography>
                    <Typography variant={"h6"}><CircularProgress size={15}/></Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant={"body1"}><CircularProgress/></Typography>

                </Box>
            </Card>
        )
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                    padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                    margin: 'auto'
                }}
            >
                <Card variant={"outlined"} sx={{ p:2, minWidth: '80%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography variant={"h6"}>{t("cart.cart")} ({cartCount})</Typography>
                        <Typography variant={"h6"}>{total}</Typography>
                    </Box>

                    {
                        (cartCount === 0 || cart.length === 0 || cart === null || cart === undefined) ? (
                            <Typography variant={"body1"}>{t("cart.empty")}</Typography>
                        ) : (
                            <Box>
                                {
                                    Object.entries(cart).map(([personId, item]) => {
                                        const person = item?.person;
                                        const products = item?.products;
                                        return (
                                            <Box key={'person-cart-' + personId}>
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row'
                                                        }}
                                                    >
                                                        <Typography>{person?.fname} {person?.sname}</Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row'
                                                        }}
                                                    >
                                                        <Typography>{person?.birthdate_str}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Typography variant={"h6"}>Auswahl für {person?.fname} {person?.sname}:</Typography>
                                                    {
                                                        products?.map((product) => {
                                                            return (
                                                                <Card
                                                                    sx={{
                                                                        p: 2,
                                                                        my: 1
                                                                    }}
                                                                    key={'product-cart-' + product.cart_item_id}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'space-between',
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                gap: '10px'
                                                                            }}
                                                                        >
                                                                            <Typography sx={{
                                                                                fontWeight: 'bold',
                                                                            }}>{product.product_name} - {product.category_name}</Typography>

                                                                            <Box>
                                                                                <Typography>{product.amount_days} Tage(e)</Typography>
                                                                                <Typography>{product.start_date} - {product.end_date}</Typography>
                                                                            </Box>
                                                                            {
                                                                                (product.times !== undefined && product.times !== null && product.times.length > 0) ? (
                                                                                    <Box>
                                                                                        <Typography sx={{
                                                                                            fontSize: '1rem',
                                                                                        }}>Zeiten</Typography>
                                                                                        {
                                                                                            product.times.map((time) => {
                                                                                                return (
                                                                                                    <Box
                                                                                                        key={'time-' + time.day_id + '_' + time.start_id}
                                                                                                        sx={{
                                                                                                            display: 'flex',
                                                                                                            flexDirection: 'row',
                                                                                                            justifyContent: 'start',
                                                                                                            gap: '20px',
                                                                                                        }}
                                                                                                    >
                                                                                                        <Typography>{time.date_str}</Typography>
                                                                                                        <Typography>{time.start_time_str} - {time.end_time_str} ({time.slot_length_str})</Typography>
                                                                                                    </Box>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </Box>
                                                                                ) : <></>
                                                                            }
                                                                            {
                                                                                (product.additional_persons_selected !== undefined && product.additional_persons_selected !== null && product.additional_persons_selected.length > 0) ? (
                                                                                    <Box>
                                                                                        <Typography sx={{
                                                                                            fontSize: '1rem',
                                                                                        }}>Zusätzliche Personen</Typography>
                                                                                        {
                                                                                            product.additional_persons_selected.map((add_person) => {
                                                                                                let add_person_name = add_person.fname + ' ' + add_person.sname;
                                                                                                // trim the name if it is longer than 20 characters
                                                                                                if (add_person_name.length > 15) {
                                                                                                    add_person_name = add_person_name.substring(0, 15) + '...';
                                                                                                }
                                                                                                return (
                                                                                                    <Box
                                                                                                        key={'add-person-' + add_person.fname + '_' + add_person.sname}
                                                                                                        sx={{
                                                                                                            display: 'flex',
                                                                                                            flexDirection: 'row',
                                                                                                            justifyContent: 'start',
                                                                                                            gap: '20px',
                                                                                                        }}
                                                                                                    >
                                                                                                        <Typography>{add_person_name}</Typography>
                                                                                                        <Typography>{add_person.birthdate}</Typography>
                                                                                                    </Box>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </Box>
                                                                                ) : <></>
                                                                            }
                                                                        </Box>
                                                                        <Box>
                                                                            <Typography>{product.product_price_txt}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    {
                                                                        ((product.cross_selling_items !== undefined && product.cross_selling_items !== null)
                                                                        && (product.cross_selling_items.length > 0)
                                                                        ) ? (
                                                                            <Box>
                                                                                {
                                                                                    product.cross_selling_items.map((cross_selling_item) => {
                                                                                        return (
                                                                                            <Box sx={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'row',
                                                                                                justifyContent: 'space-between',
                                                                                            }}
                                                                                            key={'cross-sell-item-' + cross_selling_item.item_id}
                                                                                            >
                                                                                                {(cross_selling_item.name !== undefined || cross_selling_item.name !== null)
                                                                                                ?
                                                                                                    <>
                                                                                                        <Typography>{cross_selling_item.product_name}</Typography>
                                                                                                        <Typography>{cross_selling_item.product_price_txt}</Typography>
                                                                                                    </>
                                                                                                    :
                                                                                                    <></>
                                                                                                }
                                                                                            </Box>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Box>
                                                                        )
                                                                            :
                                                                            (
                                                                                <></>
                                                                            )
                                                                    }
                                                                    {
                                                                        ((product.additional_products !== undefined && product.additional_products !== null)
                                                                        && (product.additional_products.length > 0)
                                                                        ) ? (
                                                                            <Box>
                                                                                {
                                                                                    product.additional_products_items.map((add_product_item, index) => {
                                                                                        return (
                                                                                            <Box sx={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'row',
                                                                                                justifyContent: 'space-between',
                                                                                            }}
                                                                                                key={'add-product-item-' + add_product_item.id + '-' + index}
                                                                                            >
                                                                                                {(add_product_item.name !== undefined || add_product_item.name !== null)
                                                                                                ?
                                                                                                    <>
                                                                                                        <Typography>{add_product_item.name}</Typography>
                                                                                                        <Typography>{add_product_item.total_price_txt}</Typography>
                                                                                                    </>
                                                                                                    :
                                                                                                    <></>
                                                                                                }
                                                                                            </Box>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Box>
                                                                        )
                                                                            :
                                                                            (
                                                                                <></>
                                                                            )
                                                                    }
                                                                    {
                                                                        ((product.pickup_location !== undefined && product.pickup_location !== null) &&
                                                                        product.pickup_location.id !== null && product.pickup_location.id !== undefined) ?
                                                                            (
                                                                                <Box sx={{
                                                                                    my: 1,
                                                                                    '& > *': {
                                                                                        fontSize: '1rem'
                                                                                    }
                                                                                }}>
                                                                                    <Typography><b>Abholort</b></Typography>
                                                                                    <Typography>{product.pickup_location.name}</Typography>
                                                                                    <Typography>{product.pickup_location.address}</Typography>
                                                                                    <Typography>{product.pickup_location.zip} {product.pickup_location.city}</Typography>
                                                                                    <Typography>{product.pickup_location.country}</Typography>
                                                                                    <Typography>{product.pickup_location.open_hours}</Typography>
                                                                                    <Typography>{product.pickup_location.phone}</Typography>
                                                                                    <Typography>{product.pickup_location.email}</Typography>
                                                                                </Box>
                                                                            )
                                                                            :
                                                                            (
                                                                                <></>
                                                                            )
                                                                    }
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'space-between',
                                                                            borderTop: '1px solid #ccc',
                                                                            my: 1
                                                                        }}
                                                                    >
                                                                        <IconButton
                                                                            value={`${product.cart_item_id}::${personId}`}
                                                                            onClick={() => handleRemoveFromCart({
                                                                                cart_item_id: product.cart_item_id,
                                                                                person_id: personId
                                                                                })}
                                                                        >
                                                                            <DeleteOutline/>
                                                                        </IconButton>
                                                                        <Box>
                                                                            <Typography>{product.total_price_txt}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Card>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        )
                    }
                </Card>
            </Box>
            <CartButtons backToShop={true}/>
        </>
    );
}

export default CartInventory;