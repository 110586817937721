import React from 'react';
import {useTranslation} from "react-i18next";
import {useCartStepperContext} from "../../../../common/providers/CartStepperContext";
import {useRequestContext} from "../../../../common/providers/RequestContext";
import {Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

function CartButtons({backToShop = false, disableBack = false, handleNextClick}) {

    const {t} = useTranslation();
    const { canUseNext, activeStep, steps, handleNext, handleBack } = useCartStepperContext();
    const { isRequest, allowToPay, isInRequestState } = useRequestContext();

    const navigate = useNavigate();

    const [allowNext, setAllowNext] = React.useState(false);
    const [allowBack, setAllowBack] = React.useState(false);

    const lastStep = steps.length - 1;

    const handleBackToShop = () => {
        console.log('Back to shop');
        navigate('/');
    }

    React.useEffect(() => {
        if (disableBack) {
            setAllowBack(false);
        } else {
            if (backToShop) {
                setAllowBack(true);
            } else {
                setAllowBack(activeStep !== 0);
            }
        }
    })

    React.useEffect(() => {
        console.log('canUseNext: ', canUseNext);
    }, [canUseNext]);

    return (
        (activeStep !== lastStep) ?
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between', width: '50%' }}>
                    <Button
                        color={(backToShop) ? 'success' : 'inherit'}
                        variant="contained"
                        disabled={!allowBack}
                        onClick={(backToShop) ? handleBackToShop : handleBack}
                        sx={{ mr: 1 }}
                    >
                        {
                            (backToShop) ? t('cart.btns.backToShop') : t('cart.btns.back')
                        }
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button
                        onClick={(handleNextClick) ? handleNextClick : handleNext}
                        variant="contained"
                        disabled={!canUseNext}
                    >
                        {activeStep === steps.length - 1 ? t('cart.btns.finish') : t('cart.btns.next')}
                    </Button>
                </Box>
            </Box>
            : <></>
    );
}

export default CartButtons;