import APICall from "../APICall";

class CartAPI extends APICall {

    constructor() {
        super();
    }

    async getCart() {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'cart',
                route: 'getCart',
                additionalRouteParams: [],
            },
            query: null
        });
    }

    async removeFromCart(data) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'cart',
                route: 'removeCartItem',
                additionalRouteParams: [],
            },
            data: data,
            query: null
        });
    }

    async addressCheckout(data) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'cart',
                route: 'addressCheckout',
                additionalRouteParams: [],
            },
            data: data,
            query: null
        });
    }

    async getCartType() {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'cart',
                route: 'getCartType',
                additionalRouteParams: [],
            },
            query: null
        });
    }

    async getPreparedCart(request_data) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'cart',
                route: 'getPreparedCart',
                additionalRouteParams: [],
            },
            query: null,
            data: request_data
        })
    }

    async sendRequestNote(requestNoteData=null) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'cart',
                route: 'sendRequestNote',
                additionalRouteParams: [],
            },
            query: null,
            data: requestNoteData
        })
    }

    async getNotes(processId) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'cart',
                route: 'getNotes',
                additionalRouteParams: [],
            },
            query: null,
            data: {
                process_id: processId
            }
        });
    }

    async sendRequestAction(actionData) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'cart',
                route: 'customerRequestAction',
                additionalRouteParams: [],
            },
            query: null,
            data: actionData
        });
    }

    async getRequestState(processId) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'cart',
                route: 'requestState',
                additionalRouteParams: [],
            },
            query: null,
            data: {
                process_id: processId
            }
        });
    }

    async cleanUpSession() {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'cart',
                route: 'cleanUp',
            },
            query: null,
            data: null
        })
    }
}

export default CartAPI;