import React from 'react';
import dayjs from "dayjs";

function CalendarField({ day, index, dateFields, onClick }) {

    let className = day ? 'hoverable' : 'not-selectable';
    if (day && day.isSame(dayjs(), 'day')) {
        className += ' today';
    }
    if (day && dateFields.free.includes(day.format('YYYY-MM-DD'))) {
        className += ' free';
    }
    if (day && dateFields.less.includes(day.format('YYYY-MM-DD'))) {
        className += ' less';
    }

    if (day && dateFields['day-connections'] !== undefined && dateFields['day-connections'] !== null) {
        dateFields['day-connections'].map((connection, index) => {
            if (connection.day === day.format('YYYY-MM-DD')) {
                className += ' in-selection-range';
            }
            if (connection.days_between.includes(day.format('YYYY-MM-DD'))) {
                className += ' in-selection-range';
            }
        })
    }

    const formattedDate = day ? day.format('YYYY-MM-DD') : '';

    return (
        <td key={index} className={className} onClick={(e) => onClick(e, day)} id={'sb-calendar-date-field-' + formattedDate} data-date={formattedDate}>
            {day ? day.date() : ''}
        </td>
    );

}

export default CalendarField;