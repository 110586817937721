// Create a new file named ErrorContext.js
import React, { createContext, useContext, useState } from 'react';

const ErrorContext = createContext();

export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
    const [error, setError] = useState({ isVisible: false, message: '' });

    const showError = (message, code) => {
        // console.log('showError', message, code)
        setError({ isVisible: true, message, code });
    };

    const hideError = () => {
        setError({ isVisible: false, message: '', code: null });
    };

    return (
        <ErrorContext.Provider value={{ error, showError, hideError }}>
            {children}
        </ErrorContext.Provider>
    );
};
