import React, {useRef, useState} from 'react';
import {Box, Button, Checkbox, CircularProgress, FormControlLabel, FormHelperText} from "@mui/material";
import InputField from "../../../../../../common/components/inputFields/InputField";
import {useTranslation} from "react-i18next";
import {usePersonContext} from "../../../globals/PersonContext";

function CrossSellingProduct({
                                 contentData,
                                 crossSellingSelected,
                                 handleCrossSellingSelect,
                                setCrossSellingSelected
                             }) {

    const {t} = useTranslation();

    const contentRef = useRef(null);
    const [fullHeightInRem, setFullHeightInRem] = React.useState(0);

    const initHeight = 20;

    const [height, setHeight] = React.useState(initHeight);
    const [expandSx, setExpandSx] = React.useState({
        height: initHeight + 'rem',
        overflow: 'hidden'
    });

    const initShadow = {
        '&:after': {
            content: "''",
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: '70px',
            boxShadow: 'inset 0px -70px 40px -40px #ffffff',
            overflow: 'hidden',
            zIndex: 1
        },
    }

    const [shadow, setShadow] = React.useState(initShadow);

    const [openedExpand, setOpenedExpand] = React.useState(false);
    const [maxHeightReached, setMaxHeightReached] = React.useState(false);

    const crossSellingSelectUpdate = async (e) => {

        // Check here the selected cross-selling product with the selected Dates, required fields for this product if selected.
        // If an error occurs, show the error message.

        let id = e.target.value;
        let isSelected = e.target.checked;

        if (!isSelected) {

            setCrossSellingSelected(prevState => {
                return prevState.map((item) => {
                    let oldItem = item;
                    if (item.id.toString() === id.toString()) {
                        oldItem.fields = null;
                        oldItem.data = null;
                        oldItem.additional_products = null;
                        return oldItem;
                    }
                    return item;
                })
            });
        }

        handleCrossSellingSelect(e);
    }

    const handleExpand = () => {
        setOpenedExpand(true);
        let newHeight = (height) + initHeight;
        if (newHeight > fullHeightInRem) {
            newHeight = fullHeightInRem;
            setMaxHeightReached(true);
        } else if (fullHeightInRem - newHeight < initHeight ) {
            newHeight = fullHeightInRem;
            setMaxHeightReached(true);
        }
        setExpandSx({
            height: newHeight + 'rem',
            overflow: 'hidden'
        });
        setHeight(newHeight);
    }

    const handleLess = () => {
        setOpenedExpand(false);
        let newHeight = initHeight;
        setHeight(newHeight);
        setMaxHeightReached(false);
        setExpandSx({
            height: newHeight + 'rem',
            overflow: 'hidden'
        });
    }

    React.useEffect(() => {

        if (maxHeightReached) {
            setShadow({});
        } else {
            setShadow(initShadow);
        }

    }, [maxHeightReached])

    React.useEffect(() => {
        if (contentRef.current) {
            const rootFontSize = parseFloat(
                getComputedStyle(document.documentElement).fontSize
            );
            const heightInPixels = contentRef.current.scrollHeight;
            const heightInRem = heightInPixels / rootFontSize;

            setFullHeightInRem(heightInRem)
        }
    }, []);

    return (
        <Box
            sx={{
                border: '1px solid lightgray',
                borderRadius: '5px',
            }}
        >
            <Box
                sx={{
                    p: 1,
                    position: 'relative',
                    ...expandSx
                }}
                ref={contentRef}
            >
                <Box
                    sx={{
                        ...shadow
                    }}
                >

                </Box>
                {
                    contentData.product['cross-selling'].map((item) => {
                        return (
                            <CrossSellingItem
                                key={'cross-selling-' + item.product_id}
                                crossSellingSelectUpdate={crossSellingSelectUpdate}
                                item={item}
                                crossSellingSelected={crossSellingSelected}
                                />
                        )
                    })
                }
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    variant={"text"}
                    onClick={handleExpand}
                    disabled={maxHeightReached}
                >
                    {t('simple-assistant.cross-selling.expand')}
                </Button>
                <Button
                    variant={"text"}
                    onClick={handleLess}
                    disabled={!openedExpand}
                >
                    {t('simple-assistant.cross-selling.collapse')}
                </Button>
            </Box>


        </Box>
    );

}


function CrossSellingItem({
    crossSellingSelectUpdate,
    crossSellingSelected,
    item
                          }) {

    const findItem = () => {
        return crossSellingSelected.find((element) => element.id === item.product_id);
    }

    const {updatePerson, activePerson} = usePersonContext();

    const {t} = useTranslation();

    const [previousData, setPreviousData] = useState({});
    const debounceTimer = useRef(null);

    const [selectedItem, setSelectedItem] = React.useState(findItem());

    React.useEffect(() => {
        // console.log('cs changed', crossSellingSelected);
        setSelectedItem(findItem());

        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }

    }, [crossSellingSelected])

    React.useEffect(() =>  {
        if (selectedItem === undefined || selectedItem === null) {
            return;
        }

        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }

        debounceTimer.current = setTimeout(() => {
            validateFields();
        }, 1000);
    }, [selectedItem.data]);

    const handleChangeInput = (e) => {
        // console.log(e.target.value)
        // console.log(e.target.name)

        setSelectedItem(prevState => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    [e.target.name]: e.target.value
                }
            }
        });

    }

    const validateFields = async () => {
        let validationError = null;
        let validationKey = null;

        if (selectedItem === undefined || selectedItem === null) {
            return;
        }

        if (selectedItem.data === undefined || selectedItem.data === null) {
            return;
        }

        if (selectedItem.fields === undefined || selectedItem.fields === null) {
            return;
        }

        let new_selected_data = {}

        for (const key of Object.keys(selectedItem.data)) {
            const field = selectedItem.fields.find(f => f.name === key);
            const value = selectedItem.data[key];

            // check in backend if the value is valid
            // if not, set validationErrors[key] = 'Error

            new_selected_data[key] = value;
        }

        new_selected_data['action'] = 'cross-update';
        new_selected_data['person_id'] = activePerson;

        const response = await updatePerson(new_selected_data, false, false);
        if (response === null || response === undefined) {
            selectedItem.fields.map((field) => {
                setSelectedItem(prevState => {
                    return {
                        ...prevState,
                        fields: prevState.fields.map((f) => {
                            return {
                                ...f,
                                error: null
                            }
                        })
                    }
                });
            });
            return;
        }

        if (response.message !== undefined && response.message !== null) {
            validationError = response.message;
        }

        if (response.field !== undefined && response.field !== null) {
            validationKey = response.field;
        }

        if (validationError !== null) {
            let newValError = t(`simple-assistant.errors.input-fields.${validationError}`);
            if (validationKey !== null) {
                selectedItem.fields.map((field) => {
                    if (field.name === validationKey) {
                        // console.log('HERE 4')
                        setSelectedItem(prevState => {
                            return {
                                ...prevState,
                                fields: prevState.fields.map((f) => {
                                    if (f.name === validationKey) {
                                        if (f.min !== undefined) {
                                            newValError = newValError.replace('{min}', f.min);
                                        }
                                        if (f.max !== undefined) {
                                            newValError = newValError.replace('{max}', f.max);
                                        }
                                        return {
                                            ...f,
                                            error: {
                                                message: newValError,
                                            }
                                        }
                                    } else {
                                        return {
                                            ...f,
                                            error: null
                                        }
                                    }
                                    return f;
                                })
                            }
                        });
                    }
                });
            } else {

            }
        } else {

        }
    }

    return (
        <Box
            id={'cross-selling-' + item.product_id}
            key={'cross-selling-' + item.product_id}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                justifyContent: 'start',
                alignItems: 'start',
            }}
        >
            {
                (selectedItem.loading)
                    ? (
                        <>
                            <FormControlLabel
                                control={<CircularProgress size={26} sx={{ m: 1}} />}
                                label={item.name}
                                id={'cross-selling-' + item.product_id}
                                key={'cross-selling-' + item.product_id}
                            />
                            <FormHelperText>{item.description}</FormHelperText>
                        </>
                    )
                    :
                    (
                        <>
                            <FormControlLabel
                                control={<Checkbox/>}
                                label={item.name}
                                id={'cross-selling-' + item.product_id}
                                key={'cross-selling-' + item.product_id}
                                value={item.product_id}
                                onChange={crossSellingSelectUpdate}
                                checked={selectedItem.selected || false}
                            />
                            <FormHelperText>{item.description}</FormHelperText>
                                {
                                    (selectedItem.fields !== undefined && selectedItem.fields !== null && selectedItem.fields.length > 0)
                                    ?
                                        (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'start',
                                                    ml: 2,
                                                    mb: 2
                                                }}
                                            >{
                                                selectedItem.fields.map((field) => {
                                                    return (
                                                        <InputField
                                                            key={field.id}
                                                            field={field}
                                                            value={selectedItem.data[field.name]}
                                                            t={t}
                                                            handleChange={handleChangeInput}
                                                        />
                                                    )
                                                })
                                            }
                                            </Box>
                                        )
                                    : null
                                }
                            {
                                (selectedItem.additional_products !== undefined && selectedItem.additional_products !== null && selectedItem.additional_products.length > 0)
                                ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'start',
                                            ml: 2,
                                            mb: 2
                                        }}
                                        >
                                        {
                                            selectedItem.additional_products.map((product) => {
                                                return (
                                                    <FormControlLabel
                                                        control={<Checkbox/>}
                                                        label={product.name}
                                                        id={'additional-' + product.id}
                                                        key={'additional-' + product.id}
                                                        value={product.id}
                                                        checked={(product.always_selected) ? true : (product.selected || false)}
                                                        disabled={(product.always_selected) ? true : false}
                                                    />
                                                )
                                            })
                                        }
                                    </Box>
                                    )
                                    : null
                            }
                        </>
                    )
            }
        </Box>
    )
}

export default CrossSellingProduct;