import React from 'react';
import {Box, Button, Card, Divider, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    RequestAddressComponent, RequestChatComponent,
    RequestOrderComponent,
    RequestProcessComponent, RequestProcessHistoryComponent
} from "./requestComponents/RequestComponents";
import RequestInteractions from "./requestComponents/RequestInteractions";
import {useCartContext} from "../../../../../common/providers/CartContext";
import {useRequestContext} from "../../../../../common/providers/RequestContext";
import {useTranslation} from "react-i18next";
import CartButtons from "../CartButtons";
import {useCartStepperContext} from "../../../../../common/providers/CartStepperContext";
import {useError} from "../../../../../common/errors/ErrorProvide";
import {ContentCopy} from "@mui/icons-material";

function RequestCheck() {

    const { t } = useTranslation();
    const { requestData, requestUrl } = useRequestContext();

    const [activeState, setActiveState] = React.useState(null);
    const [history, setHistory] = React.useState(null);
    const {requestState, checkRequestState, requestHistory} = useCartContext();
    const { setCanUseNext } = useCartStepperContext();
    const { showError } = useError();

    const checkNewRequestState = (processId) => {
        checkRequestState(processId);
    }

    React.useEffect(() => {
        setActiveState(requestState);
        setHistory(requestHistory);
    }, [requestState, requestHistory]);

    React.useEffect(() => {
        setCanUseNext(false);
    }, []);

    const handleCopyUrl = () => {
        if (requestUrl !== undefined && requestUrl !== null) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(requestUrl).then(() => {
                    console.log('Request URL copied to clipboard');
                })
                    .catch((e) => {
                        console.log('Error copying to clipboard: ', e);
                        showError('Error copying to clipboard');
                    });
            } else {
                showError('No clipboard available');
            }
        } else {
            showError('No request URL available');
        }
    }

    console.log('Request Data: ', requestData);

    if (requestData === null) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                    padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                    margin: 'auto'
                }}
            >
                <Card
                    variant={"outlined"}
                    sx={{p: 2, maxWidth: '700px', width: '100%'}}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant={"h6"}>{t('cart.request.title')}</Typography>
                        <p>
                            {t('cart.request.no_billing')} <br/>
                        </p>
                    </Box>
                </Card>
                <CartButtons />
            </Box>
        )
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                    padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                    margin: 'auto'
                }}
            >
                <Card
                    variant={"outlined"}
                    sx={{p: 2, maxWidth: '700px', width: '100%'}}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                                paddingBottom: '5px'
                            }}
                        >
                            <Typography variant={"h5"}>{t('cart.request.title')}</Typography>
                        </Box>
                        <Divider sx={{width: '100%'}}/>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            pt: 1
                        }}
                    >
                        <TextField
                            label={t('cart.request.requestUrl')}
                            value={requestUrl}
                            variant={"outlined"}
                            size={"small"}
                            fullWidth
                            disabled
                        />
                        <Button
                            variant={"contained"}
                            color={"inherit"}
                            onClick={handleCopyUrl}
                            sx={{
                                ml: 1
                            }}
                            startIcon={<ContentCopy/>}
                        >
                            {t('cart.request.btns.copy')}
                        </Button>
                    </Box>
                    {
                        (requestData !== undefined && true && requestData.billing !== undefined) ? (
                            <RequestAddressComponent t={t} billing={requestData.billing}/>
                        ) : (
                            <></>
                        )
                    }
                    {
                        (requestData !== undefined && true && requestData.process !== undefined) ? (
                            <>
                                <RequestProcessComponent t={t} activeState={activeState} checkNewRequestState={checkNewRequestState} process={requestData.process}/>
                            </>

                        ) : (
                            <></>
                        )
                    }
                    {
                        (requestData !== undefined && true && requestData.process !== undefined) ?
                            <RequestInteractions t={t} checkNewRequestState={checkNewRequestState} activeState={activeState} process={requestData.process}/>
                            :
                            <></>
                    }
                    {
                        (requestData !== undefined && true && requestData.process !== undefined && history !== undefined && history !== null) ? (
                            <RequestProcessHistoryComponent t={t} history={history}/>
                        ) : (
                            <></>
                        )
                    }
                    {
                        (requestData !== undefined && true && requestData['billing-orders'] !== undefined && requestData['pre-creation'] !== undefined) ? (
                            <RequestOrderComponent t={t} orders={requestData['billing-orders']} preCreation={requestData['pre-creation']}/>
                        ) : (
                            <></>
                        )
                    }
                    {
                        (requestData !== undefined && true && requestData.process !== undefined && requestData.process.notes !== undefined) ? (
                            <RequestChatComponent t={t} chat={requestData.process.notes} processId={requestData.process.id}/>
                        ) : (
                            <></>
                        )
                    }
                </Card>
            </Box>
            <CartButtons disableBack={true} />
        </>
    );
}

export default RequestCheck;