import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel, FormHelperText
} from "@mui/material";

function ContinueAfterAddCart({
    t,
    isMobile,
    open,
    addToCart,
    close,
    additionalProducts,
    crossSellingItems,
    handleCrossSellingSelect,
    handleAdditionalProductSelect,
    crossSellingSelected
    }) {

    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle>
                {t('simple-assistant.continueAfterAddToCart')}
            </DialogTitle>
            <DialogContent>

                {
                    crossSellingItems && crossSellingItems.length > 0 &&
                    <Box>
                        {
                            crossSellingItems.map((item) => {
                                const isSelected = crossSellingSelected.some(x => x.id === item.product_id && x.selected);
                                return (
                                    <Box
                                        key={'cross-selling-after-add-' + item.product_id}
                                    >
                                        <FormControlLabel
                                            control={<Checkbox/>}
                                            label={item.name}
                                            id={'cross-selling-after-add-' + item.product_id}
                                            name={'cross-selling-after-add-' + item.product_id}
                                            value={item.product_id}
                                            onChange={(e) => handleCrossSellingSelect(e)}
                                            checked={isSelected}
                                        />
                                        <FormHelperText>{item.description}</FormHelperText>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                }
                {
                    additionalProducts && additionalProducts.length > 0 &&
                    <Box>
                        {
                            additionalProducts.map((item) => {
                                return (
                                    <Box
                                        key={'additional-after-add-' + item.id}
                                    >
                                        <FormControlLabel
                                            control={<Checkbox/>}
                                            label={item.name}
                                            id={'additional-after-add-' + item.id}
                                            name={'additional-after-add-' + item.id}
                                            value={item.id}
                                            onChange={handleAdditionalProductSelect}
                                            checked={item.selected || false}
                                        />
                                        <FormHelperText>{item.description}</FormHelperText>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                }
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Button onClick={close} color="primary">
                    {t('simple-assistant.cancelAddingToCart')}
                </Button>
                <Button onClick={addToCart} color="primary" variant="contained">
                    {t('simple-assistant.addToCart')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ContinueAfterAddCart;