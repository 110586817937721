import React from 'react';
import ProcessorComponent from "../../templates/simpleAssistant/ProcessorComponent";
import {PersonProvider} from "../../templates/simpleAssistant/globals/PersonContext";
import {MobileViewProvider} from "../../templates/simpleAssistant/globals/MobileViewContext";

function AssistantHandler(props) {
    return (
        <MobileViewProvider>
            <PersonProvider>
                <ProcessorComponent />
            </PersonProvider>
        </MobileViewProvider>
    );
}

export default AssistantHandler;