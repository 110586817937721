import React, {useContext, createContext, useState} from 'react';
import SimpleAssistantAPI from "../../../../common/handlers/api-handlers/SimpleAssistantAPI";
import {useError} from "../../../../common/errors/ErrorProvide";

const ProductContext = createContext();

export const useProductContext = () => useContext(ProductContext);

export const ProductProvider = ({children}) => {
    const [productsLoading, setProductsLoading] = useState(false);
    const [products, setProducts] = useState(null);
    const API = new SimpleAssistantAPI();
    const {showError} = useError();
    const [activeProduct, setActiveProduct] = useState(null);

    const _apiResponseError = (response) => {
        showError(response);
    }

    const _getProductsFromAPI = async (personId) => {
        // bla
        setProductsLoading(true);
        const [success, response] = await API.getProducts(personId);
        if (success) {
            const data = response;
            setProducts(data.products);
        } else {
            setProducts([])
            _apiResponseError(response);
        }
        setProductsLoading(false);
    }

    const getProducts = async (personId) => {
        await _getProductsFromAPI(personId);
    }

    return (
        <ProductContext.Provider
            value={{
                // bla
                productsLoading,
                getProducts,
                products,
                setProducts,
                activeProduct,
                setActiveProduct
            }}>
            {children}
        </ProductContext.Provider>
    );
}