import React, {useEffect} from 'react';
import {Box, CircularProgress, Step, StepLabel, Stepper, Typography} from "@mui/material";
import CartInventory from "./components/CartInventory";
import AddressField from "./components/AddressField";
import {useTranslation} from "react-i18next";
import {useError} from "../../../../common/errors/ErrorProvide";
import PaymentSelection from "./components/PaymentSelection";
import ThankYou from "./components/ThankYou";
import RequestCheck from "./components/RequestCheck";
import {MobileViewProvider} from "../../../templates/simpleAssistant/globals/MobileViewContext";
import {useRequestContext} from "../../../../common/providers/RequestContext";
import {useCartStepperContext} from "../../../../common/providers/CartStepperContext";

const steps = {
    'default': ['cart.stepper.cart', 'cart.stepper.address', 'cart.stepper.payment', 'cart.stepper.confirmation'],
    'request': ['cart.stepper.cart', 'cart.stepper.address', 'cart.stepper.request', 'cart.stepper.payment', 'cart.stepper.confirmation'],
};

function CartStepper({initActiveStep = 0, initCartType = null, initRequestData = null, directThankYou=false}) {
    const {t} = useTranslation();
    const { showError } = useError();
    const { isRequest, setUnsetRequest, setIsInRequestState, requestData, } = useRequestContext();
    const { setCartType, loadAddress, cartType, isLoading, getCartType, activeStep, handleNext, handleBack, handleAddressChange, setActiveStep, address } = useCartStepperContext();

    React.useEffect(() => {
        setActiveStep(initActiveStep);
        getCartType(initCartType);
        loadAddress();
    }, []);

    React.useEffect(() => {
        setCartType(initCartType);
    }, [initCartType]);

    if (cartType === null) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <MobileViewProvider>
            <Box>
                <Stepper sx={{
                    mb: 2,
                }} activeStep={activeStep} alternativeLabel>
                    {
                        steps[cartType].map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            return (
                                <Step key={label + index} {...stepProps}>
                                    <StepLabel {...labelProps}>{t(label)}</StepLabel>
                                </Step>
                            )
                        })
                    }
                </Stepper>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <CartStepperContentSelector
                        activeStep={activeStep}
                        address={address}
                        setActiveStep={setActiveStep}
                        handleAddressChange={handleAddressChange}
                        t={t}
                        requestData={requestData}
                        directThankYou={directThankYou}
                        handleStepCompleted={handleNext}
                    />
                )
                }

                {/*<CartStepperBtnSelector*/}
                {/*    cartType={cartType}*/}
                {/*    activeStep={activeStep}*/}
                {/*    handleBack={handleBack}*/}
                {/*    handleNext={handleNext}*/}
                {/*    t={t}*/}
                {/*    canUseNext={canUseNext}*/}
                {/*/>*/}
            </Box>
        </MobileViewProvider>
    );
}

function CartStepperContentSelector({activeStep, address, handleAddressChange, t, requestData = null, directThankYou=false, handleStepCompleted}) {
    const { setActiveStep, cartType } = useCartStepperContext();

    useEffect(() => {
        if (directThankYou && cartType === 'default') {
            setActiveStep(3);
        } else if (directThankYou && cartType === 'request') {
            setActiveStep(4);
        }
    }, []);

    if (cartType === null) {
        return <CircularProgress />
    } else if (cartType === 'default') {
        return <CartStepperContentDefault activeStep={activeStep} setActiveStep={setActiveStep} address={address} handleAddressChange={handleAddressChange} t={t} handleStepCompleted={handleStepCompleted} />
    } else if (cartType === 'request') {
        return <CartStepperContentRequest activeStep={activeStep} setActiveStep={setActiveStep} address={address} handleAddressChange={handleAddressChange} t={t} requestData={requestData} handleStepCompleted={handleStepCompleted} />
    } else {
        return <Typography>Cart type not found</Typography>
    }
}

function CartStepperContentDefault({activeStep, setActiveStep, address, handleAddressChange, t, handleStepCompleted}) {
    return (
        <Box>
            {activeStep === 0 && <CartInventory />}
            {activeStep === 1 && <AddressField />}
            {activeStep === 2 && <PaymentSelection t={t} handleStepCompleted={handleStepCompleted}/>}
            {activeStep === 3 && <ThankYou setActiveStep={setActiveStep} activeStep={activeStep}/>}
        </Box>
    )
}

function CartStepperContentRequest({activeStep, setActiveStep, address, handleAddressChange, t, requestData = null, handleStepCompleted}) {
    return (
        <Box
            // sx={{
            //     display: 'flex',
            //     flexDirection: 'column',
            //     alignItems: 'center',
            //     maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
            //     padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
            //     margin: 'auto'
            // }}
        >
            {activeStep === 0 && <CartInventory />}
            {activeStep === 1 && <AddressField />}
            {activeStep === 2 && <RequestCheck /> }
            {activeStep === 3 && <PaymentSelection />}
            {activeStep === 4 && <ThankYou />}
        </Box>
    )
}

function CartStepperBtnSelector({cartType, activeStep, handleBack, handleNext, t, canUseNext}) {

    let lastStep = steps[cartType].length - 1;

    return <CartStepperBtnComponent lastStep={lastStep} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} t={t} canUseNext={canUseNext}/>
}

function CartStepperBtnComponent({cartType, lastStep, activeStep, handleBack, handleNext, t, canUseNext}) {
    // console.log('can use next: ', canUseNext)
    const { isRequest, allowToPay, isInRequestState } = useRequestContext();
    const [disableNext, setDisableNext] = React.useState(true);

    React.useEffect(() => {
        if (isRequest && isInRequestState && allowToPay) {
            setDisableNext(false);
            return;
        } else if (isRequest && isInRequestState && !allowToPay) {
            setDisableNext(true);
            return;
        }

        if (canUseNext) {
            setDisableNext(false);
        } else {
            setDisableNext(true);
        }
    }, [canUseNext, isInRequestState, allowToPay, isRequest]);
}

export default CartStepper;