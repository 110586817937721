import {Outlet, Route} from "react-router-dom";
import React from "react";

import CartPrepared from "./cart/CartPrepared";
import CartStepper from "./cart/CartStepper";
import {RequestProvider} from "../../../common/providers/RequestContext";
import {CartStepperProvider} from "../../../common/providers/CartStepperContext";

export const CartRoutes = [
    <Route key={"cart"} path={"/cart"} element={
        <RequestProvider>

            <CartStepperProvider>
                    <CartStepper directThankYou={false}/>
            </CartStepperProvider>
        </RequestProvider>

    } />,
    <Route key={"cart-prepared"} path={"/cart/prepared/*"} element={
        <RequestProvider>
            <CartStepperProvider>
                <CartPrepared />
            </CartStepperProvider>
        </RequestProvider>
            } />,
    // <Route key={"payment"} path={"/cart/payment"} element={<Cart />} />,
    // <Route key={"checkout"} path={"/cart/checkout"} element={<Cart />} />,
    <Route key={"thank-you"} path={"/cart/thank-you"} element={
        <RequestProvider>
            <CartStepperProvider>
                    <CartStepper directThankYou={true}/>
            </CartStepperProvider>
        </RequestProvider>
    } />,
]

function CartPages(props) {
    return (
        <Outlet />
    );
}

export default CartPages;