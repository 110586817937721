import React from 'react';
import {NavLink} from "react-router-dom";
import {Icon, IconButton, Link, Tooltip, Typography, Zoom} from "@mui/material";
import {DynamicMuiIcon} from "../../../templates/TemplateLoader";

function MenuItem({
    index, item
                  }) {

    return (
        <Link component={NavLink} to={item.link} sx={{
            color: '#ffffff',
            margin: item.extraMargin,
            '&:hover': {
                color: '#182254',
                textDecoration: 'none',
            }
        }} underline="none"
        >

            <Tooltip title={(item.tooltip) ? item.tooltip : null} TransitionComponent={Zoom} arrow>
                {
                    item.displayIcon ?
                        <IconButton sx={{ color: 'inherit' }} size={'large'}>
                            {item.displayIcon ?
                                <DynamicMuiIcon iconName={item.icon} />
                                :
                                <Icon>{item.name}</Icon>
                            }
                        </IconButton>
                        : <Typography>{item.description}</Typography>
                }
            </Tooltip>
        </Link>
    );
}

export default MenuItem;