import React from 'react';
import PaymentAPI from "../../../../../../../common/handlers/api-handlers/PaymentAPI";
import {useNavigate} from "react-router-dom";

function HobexCheckoutForm(props) {

    const paymentApi = new PaymentAPI();

    const [scriptUrl, setScriptUrl] = React.useState(null);
    const [checkoutId, setCheckoutId] = React.useState(null);
    const [paymentBrands, setPaymentBrands] = React.useState(null);
    const [fullScriptUrl, setFullScriptUrl] = React.useState(null);

    const [isLoading, setIsLoading] = React.useState(false);

    const navigator = useNavigate();

    const returnUrl = process.env.REACT_APP_KB_PAYMENT_RETURN_URL;

    const handleSubmit = async () => {

        // console.log('checking status...');

        setIsLoading(true);
        const [success, response] = await paymentApi.hobexStatus();
        if (success) {
            navigator(returnUrl);
        } else {
            console.log(`Could not get Hobex Checkout status... ${response}`);
        }
        // now check here for payment status in backend
        // if payment is successful, redirect to returnUrl
        // if payment is not successful, show error message
        // make requests until there's a valid response except for 3 times every 3 seconds
    }

    const initHobex = async () => {
        const [success, response] = await paymentApi.hobexInit();
        // console.log(response, success);
        if (success) {
            setScriptUrl(response.data.url);
            setPaymentBrands(response.data.payment_brands);
        } else {
            console.log(`Could not initiate Hobex Checkout... ${response}`);
        }
        return success;
    }

    const prepareCheckout = async () => {
        const [success, response] = await paymentApi.createPaymentIntent('hobex');
        if (success) {
            setCheckoutId(response.id);
        }
        return success;
    }

    const createHobexCheckout = async () => {
        const initSuccess = await initHobex();
        if (!initSuccess) {
            return;
        }

        const success = await prepareCheckout();
        if (!success) {
            return;
        }
    }

    React.useEffect(() => {
        createHobexCheckout();
    }, []);

    React.useEffect(() => {
        if (scriptUrl && checkoutId) {
            // console.log('setting full script url...');
            setFullScriptUrl(`${scriptUrl}/v1/paymentWidgets.js?checkoutId=${checkoutId}`);
        }
    }, [scriptUrl, checkoutId]);

    React.useEffect(() => {
        // console.log('fullScriptUrl', fullScriptUrl);
        if (fullScriptUrl) {
            // load the script
            // console.log('loading script...');
            const script = document.createElement('script');
            script.src = fullScriptUrl;
            script.async = true;
            document.head.appendChild(script);
        }
    }, [fullScriptUrl]);

    if ((!fullScriptUrl && !paymentBrands) || isLoading) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    return (
        <div key='hobex-payment-components'>
            <p>PAYMENT</p>
            {/*<script src={fullScriptUrl}></script>*/}
            <form
                onSubmit={handleSubmit}
                action={process.env.REACT_APP_KB_PAYMENT_RETURN_URL}
                className="paymentWidgets"
                data-brands={paymentBrands}>
            </form>
        </div>
    );
}

export default HobexCheckoutForm;