import React from 'react';
import {
    AddShoppingCart,
    ErrorOutline,
    GridOn,
    GridView,
    List, ShoppingBag, ShoppingCart,
    Style,
    ViewAgenda,
    ViewCarousel,
    ViewCompact,
    ViewList,
    ViewModule,
    Window,
    Chat,
    Home,
    Inventory,
    MenuBook,
    PedalBike,
    Person
} from "@mui/icons-material";
import {Box, CircularProgress} from "@mui/material";


const Icons ={
    'List': List,
    'ViewList': ViewList,
    'ViewAgenda': ViewAgenda,
    'Style': Style,
    'ViewCarousel': ViewCarousel,
    'ViewCompact': ViewCompact,
    'Window': Window,
    'ViewModule': ViewModule,
    'GridView': GridView,
    'GridOn': GridOn,
    'ShoppingCart': ShoppingCart,
    'ShoppingBag': ShoppingBag,
    'AddShoppingCart': AddShoppingCart,
    'PedalBike': PedalBike,
    'Chat': Chat,
    'Home': Home,
    'Inventory': Inventory,
    'Person': Person,
    'MenuBook': MenuBook,
}

const DynamicMuiIcon = ({ iconName, ...props }) => {
    const IconComponent = Icons[iconName] || ErrorOutline;
    return <IconComponent {...props} />;
}

const DynamicTemplate = ({ templateName, ...props }) => {
    // make first letter lowercase
    const templateView = templateName.charAt(0).toUpperCase() + templateName.slice(1);
    const TemplateComponent = React.lazy(() =>
        import(`./${templateName}/${templateView}`).catch(() => import('./errorTemplate/ErrorTemplate'))
    );

    return (
        <React.Suspense fallback={
            <Box
                sx={{
                    p: 2,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </Box>
        }>
            <TemplateComponent {...props} />
        </React.Suspense>
    );
}

const DynamicView = ({ templateName, viewName, ...props }) => {
    // make first letter lowercase
    const ViewComponent = React.lazy(() =>
        import(`./${templateName}/${viewName}`).catch(() => import('./errorTemplate/ErrorTemplate'))
    );

    return (
        <React.Suspense fallback={<span>Loading...</span>}>
            <ViewComponent {...props} />
        </React.Suspense>
    );
}

const DynamicLandingPage = ({ templateName, ...props}) => {
    const LandingPageComponent = React.lazy(() =>
        import(`./${templateName}/LandingPage`).catch(() => import('./errorTemplate/ErrorTemplate'))
    );

    return (
        <React.Suspense fallback={<span>Loading...</span>}>
            <LandingPageComponent {...props} />
        </React.Suspense>
    );
}

const LoadTemplateFile = (isSimpleAssistant=false) => {
    // file in public template/template.json
    // fetch file
    // return file

    const template = fetch('/template/template.json')
        .then((response) => response.json())
        .then((data) => {
            if (isSimpleAssistant) {
                if (data["simple_assistant"] === undefined) {
                    return null;
                }
                return data.simple_assistant;
            }
            if (data.template === undefined) {
                return null;
            }
            return data.template;
        });

    return template;
}


const LoadMenuFile = () => {
    // file in public template/menu.json
    // fetch file
    // return file

    const menu = fetch('/template/menu.json')
        .then((response) => response.json())
        .then((data) => {
            return data;
        });

    return menu;
}

const LoadPageStyles = () => {
    // file in public template/pageStyles.json
    // fetch file
    // return file

    const pageStyles = fetch('/template/page-styles.json')
        .then((response) => response.json())
        .then((data) => {
            return data;
        });

    return pageStyles;
}

export {
    DynamicMuiIcon,
    DynamicTemplate,
    DynamicView,
    DynamicLandingPage,
    LoadTemplateFile,
    LoadMenuFile,
    LoadPageStyles,
};