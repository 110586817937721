import React from 'react';
import { Badge, Box, IconButton, Link, Tooltip, Zoom} from "@mui/material";
import {Person, ShoppingCart} from "@mui/icons-material";
import {NavLink} from "react-router-dom";
import MenuItem from "./components/MenuItem";
import LogoMenuItem from "./components/LogoMenuItem";
import MobileMenu from "./components/MobileMenu";
import MoreMenu from "./components/MoreMenu";
import { useTranslation } from 'react-i18next';
import {useCartContext} from "../../../common/providers/CartContext";


function Header({menuItems, pageStyles}) {

    // detect page width and set the menu items as hamburger menu
    // if the width is less than 800px

    const { t } = useTranslation();

    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 800;
    const [isMobile, setIsMobile] = React.useState(false);

    const {cartAmount, getCart} = useCartContext();

    React.useEffect(() => {
        getCart();
    }, []);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        setIsMobile(width < breakpoint);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [window.innerWidth]);

    return (
        <div style={{
            padding: '0 2rem',
            color: '#fff',
        }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    maxWidth: '100%',
                    background: pageStyles.header.backgroundColor,
                    borderRadius: '0 0 10px 10px',
                    padding: '5px 20px',
                }}
            >
                {
                    (menuItems.length > 1) ? (
                        <LogoMenuItem img_src={"img/logo_white.png"} maxHeight={"50px"} destination={'/'} />
                    )
                        :
                        (menuItems.length === 1)
                            ?
                            (<LogoMenuItem img_src={"img/logo_white.png"} maxHeight={"50px"} destination={'/'}/>)
                            :
                            (<></>)
                }

                {
                    (isMobile) ? (
                        <MobileMenu menuItems={menuItems} cartAmount={cartAmount}/>
                    ) : (
                        <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: '10px',
                        }}>
                            {
                                menuItems.map((item, index) => {
                                    if (index < 6) {
                                        return (
                                            <MenuItem item={item} index={index} key={"menu-item" + index}/>
                                        );
                                    }
                                })
                            }
                            {
                                (menuItems.length > 6) ?
                                    (<MoreMenu menuItems={menuItems} />)
                                    :
                                    (<></>)
                            }
                        </div>
                        <div>
                            <Tooltip title={t('header.my-profile')} TransitionComponent={Zoom} arrow>
                                <IconButton sx={{color: '#ffffff'}} size={'large'}>
                                    <Person sx={{fontSize: '30px'}}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('header.cart')} TransitionComponent={Zoom} arrow>
                                <Link to={'/cart'} component={NavLink}>
                                    <IconButton sx={{color: '#ffffff'}} size={'large'}>
                                        <Badge badgeContent={cartAmount} sx={{
                                            '& .MuiBadge-badge': {
                                                backgroundColor: '#ce4242',
                                                fontWeight: 'bold',
                                            },
                                        }}>
                                            <ShoppingCart sx={{fontSize: '30px'}}/>
                                        </Badge>
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </div>
                        </>
                    )
                }
            </Box>
        </div>
    );
}

export default Header;