import React from 'react';
import {Box, ButtonGroup, Link, Skeleton} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function MainPage(props) {

    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 800;
    const [isMobile, setIsMobile] = React.useState(false);
    const [footerStyling, setFooterStyling] = React.useState({});

    const handleWindowSize = (width) => {
        setIsMobile(width < breakpoint);
        if (width > breakpoint) {
            setFooterStyling({
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
                py: 1.5,
                px: 3,
                mt: 2,
                position: 'absolute',
                bottom: '0',
                width: '100%',
            })
        } else {
            setFooterStyling({
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
                py: 1.5,
                px: 3,
                mt: 2,
            })
        }
    }

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        handleWindowSize(width);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        }
    }, [window.innerWidth, window.innerHeight]);

    React.useEffect(() => {
        setWidth(window.innerWidth);
        handleWindowSize(width);
    }, [])


    return (
        <>
            <div style={{
                padding: '0 2rem',
                color: '#fff',
            }}>
                <Skeleton variant="rectangular"
                          height={50}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        maxWidth: '100%',
                        background: 'linear-gradient(90deg, rgb(60, 60, 60) 0%, rgb(112, 112, 112) 35%, rgb(112, 112, 112) 100%)',
                        borderRadius: '0 0 10px 10px',
                        padding: '5px 20px',
                    }}
                />
            </div>
            <Box sx={{ p:2 }}>
                <Skeleton variant="rounded" height={(isMobile) ? 200 : 800} />
            </Box>
            <Box
                id={'footer'}
                component="footer"
                sx={footerStyling}
            >
                <Container maxWidth="lg">
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={4}>
                            <Skeleton variant="text" width={180} sx={{ fontSize: '2rem' }} />
                            <Skeleton variant="rounded" height={90} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Skeleton variant="text" width={180} sx={{ fontSize: '2rem' }} />
                            <Skeleton variant="rounded" height={90} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Skeleton variant="text" width={180} sx={{ fontSize: '2rem' }} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    gap: '10px',
                                }}
                                >
                                <Skeleton variant="rounded" width={30} height={30} />
                                <Skeleton variant="rounded" width={30} height={30} />
                            </Box>
                            <div>
                                <ButtonGroup
                                    color='info'
                                    orientation="horizontal"
                                    size="medium"
                                    variant="text"
                                    sx={{
                                        marginTop: '10px',
                                    }}
                                >
                                    <Skeleton variant="rounded" width={100} height={50} />
                                </ButtonGroup>
                            </div>
                        </Grid>
                    </Grid>
                    <Box mt={5}>
                        <Typography variant="body2" color="text.secondary" align="center">
                            {"Copyright © "}
                            <Link color="inherit" href="https://komm-it.info/">
                                FunkeSolution GmbH
                            </Link>{" "}
                            {new Date().getFullYear()}
                            {"."}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="center">
                            <Link color={'inherit'} href={'https://komm-it.info/impressum/'}>Impressum</Link>
                            {" | "}
                            <Link color={'inherit'} href={'https://komm-it.info/datenschutz/'}>DSGVO</Link>
                            {" | "}
                            <Link color={'inherit'} href={'https://komm-it.info/agb/'}>AGB</Link>
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </>

    );
}

export default MainPage;