import React from 'react';
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import SliderComponent from "../../../../templates/simpleAssistant/footer/SliderComponent";
import {useCartContext} from "../../../../../common/providers/CartContext";
import PaymentAPI from "../../../../../common/handlers/api-handlers/PaymentAPI";
import {useError} from "../../../../../common/errors/ErrorProvide";
import {useNavigate} from "react-router-dom";

function ThankYou() {

    const [isLoading, setIsLoading] = React.useState(false);
    const [requestComplete, setRequestComplete] = React.useState(false);

    const [paymentStatus, setPaymentStatus] = React.useState(null);
    const [allowToCleanUp, setAllowToCleanUp] = React.useState(false);
    const [paymentResponse, setPaymentResponse] = React.useState(null);

    const paymentApi = new PaymentAPI();
    const navigate = useNavigate();
    const {showError} = useError();

    const { cleanUpSession } = useCartContext();

    const {t} = useTranslation();

    React.useEffect(() => {
        if (requestComplete && allowToCleanUp) {
            cleanUpSession();
        }
    }, [allowToCleanUp])

    const handleResponse = async (response) => {
        let cleanup = response.allow_clean_up;
        let message = response.message;
        let status = response.status;

        setPaymentStatus(status);
        setAllowToCleanUp(cleanup);
        if (status === 'PENDING') {
            setTimeout(() => {
                checkPaymentStatus();
            }, 500)
        } else {
            setRequestComplete(true);
        }

        setPaymentResponse(response);
    }

    const checkPaymentStatus = async () => {
        setIsLoading(true);
        const [success, response] = await paymentApi.thankYou();
        if (success) {
            await handleResponse(response)
            setIsLoading(false);
        } else {
            // console.log(`Could not get Hobex Checkout status... ${response}`);
        }
    }

    const handleReturnToPayment = () => {
        // let new_active = activeStep - 1;
        // setActiveStep(new_active);
        // console.log('return to payment', new_active);
        // console.log('activeStep', activeStep);

        navigate('/cart');
    }

    React.useEffect(() => {
        checkPaymentStatus();
    }, [])

    if (isLoading) {
        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                        padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                        margin: 'auto'
                    }}
                >
                    <Box>
                        <CircularProgress />
                    </Box>
                </Box>
                <SliderComponent />
            </>
        )
    }

    if (requestComplete) {
        if (paymentStatus === 'SUCCESS') {
            return (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                            padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                            margin: 'auto'
                        }}
                    >
                        <Typography variant={"h1"} sx={{my: 5 }}>{t('cart.thank-you')}</Typography>
                    </Box>
                    <SliderComponent />
                </>
            );
        } else if (paymentStatus === 'REJECTED') {
            return (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                            padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                            margin: 'auto'
                        }}
                    >
                        <Typography variant={"h1"} sx={{my: 5 }}>{t('cart.payment-rejected')}</Typography>
                        {
                            (paymentResponse && paymentResponse.description)
                            ? (
                                <p>{paymentResponse.description}</p>
                                )
                            :
                                null
                        }
                        <Button
                            variant={"contained"}
                            sx={{my: 5}}
                            onClick={handleReturnToPayment}
                            >
                            {t('cart.return-to-cart')}
                        </Button>
                    </Box>
                    <SliderComponent />
                </>
            )
        } else {
            return (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                            padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                            margin: 'auto'
                        }}
                    >
                        <Typography variant={"h1"} sx={{my: 5 }}>{t('cart.payment-pending')}</Typography>
                        <Typography variant={"body1"} sx={{my: 5 }}>{t('cart.payment-pending-info')}</Typography>
                    </Box>
                    <SliderComponent />
                </>
            )
        }
    }
    return (
        <>
            <Typography variant={"h1"} sx={{my: 5 }}>{t('cart.thank-you')}</Typography>
            <SliderComponent />
        </>
    );
}

export default ThankYou;