import React from 'react';
import {Box, Drawer, IconButton, MenuItem, Tooltip, Typography} from "@mui/material";
import {Close, Menu} from "@mui/icons-material";

function MoreMenuItem({item}) {
    return (
        <MenuItem
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                backgroundColor: 'rgba(0,0,0,0.1)',
                borderRadius: '10px',
                '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    cursor: 'pointer',
                }
            }}
        >
            <Typography>{item.description}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {
                    (item.displayIcon) ? (
                        item.icon
                    ) : ( <></> )
                }
            </Box>
        </MenuItem>
    );
}

function MoreMenu({menuItems}) {

    const [openMoreMenu, setOpenMoreMenu] = React.useState(false);

    const openMore = () => {
        setOpenMoreMenu(!openMoreMenu);
    }

    return (
        <>
        <Tooltip title={"Mehr"}>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={openMore}
                sx={{ml: 2}}
            >
                <Menu sx={{ fontSize: '25px' }} />
            </IconButton>
        </Tooltip>
        <Drawer
            anchor={'right'}
            open={openMoreMenu}
            onClose={openMore}
            elevation={10}
        >
            <Box
                sx={{
                    width: 250,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    padding: '20px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={openMore}
                    >
                        <Close sx={{ fontSize: '25px' }} />
                    </IconButton>
                    <Typography variant={'h6'}>Mehr...</Typography>
                </Box>
                {
                    menuItems.map((item, index) => {
                        if (index > 5) {
                            return (
                                <MoreMenuItem key={"more-menu-item" + index} item={item}/>
                            );
                        } else {
                            return (<></>)
                        }
                    })
                }
            </Box>
        </Drawer>
        </>
    );
}

export default MoreMenu;