import React from 'react';
import {Outlet, Route} from "react-router-dom";
import AssistantHandler from "./AssistantHandler";

export const ShopRoutes = [
    <Route key={"/simple-assistant"} path={"/shop/simple-assistant"} element={<AssistantHandler />} />,
    // <Route key={"/*"} path={"/shop/*"} element={<ViewHandler />} />,
    // <Route key={"/"} path={"/shop/"} element={<ViewHandler />} />,
]

function ShopPages(props) {
    return (
        <Outlet />
    );
}

export default ShopPages;