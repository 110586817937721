import React from 'react';
import {Box, Button, Card} from "@mui/material";
import PersonHeadComponent from "./PersonHeadComponent";
import {useTranslation} from "react-i18next";
import {useMobileViewContext} from "../globals/MobileViewContext";
import {useCartContext} from "../../../../common/providers/CartContext";
import {useNavigate} from "react-router-dom";

const initCardSx = {
    display: 'flex',
    flex: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    p: 1
};

const initBoxSx = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
}

function HeaderComponent(props) {
    const [cardSx, setCardSx] = React.useState(initCardSx);
    const [boxSx, setBoxSx] = React.useState(initBoxSx);
    const {t} = useTranslation();
    const {cartPrice} = useCartContext();

    const [logo, setLogo] = React.useState('');

    const navigator = useNavigate();
    const {viewType} = useMobileViewContext();

    const handleMoveToCart = () => {
        navigator('/cart');
    }

    React.useEffect(() => {
        let newCardSx = {...initCardSx};
        let newBoxSx = {...initBoxSx};

        if (viewType === 'mobile' || viewType === 'tablet') {
            newCardSx.flexDirection = 'column-reverse';
            newBoxSx.paddingBottom = '10px';
        }

        setCardSx(newCardSx);
        setBoxSx(newBoxSx);
    }, [viewType]);

    React.useEffect(() => {
        if (props.template !== null) {
            setLogo(props.template.logo);
        } else {
            setLogo('');
        }
    }, [props.template]);

    return (
        <Card
            id={'simple-assistant-header'}
            sx={
                cardSx
            }
        >
            <PersonHeadComponent persons={props.persons}/>
            <Box
                sx={boxSx}
            >
                <img width={'auto'} height={'auto'}
                     src={logo} alt={'logo'}
                     style={{
                         maxWidth: '250px',
                         maxHeight: '250px'
                     }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '75%',
                        height: '100%',
                        padding: 'auto',
                        margin: 'auto'
                    }}
                >
                    <p>{t('simple-assistant.price')}: {cartPrice}</p>
                    <Button variant={"outlined"} onClick={handleMoveToCart}>{t('simple-assistant.moveToCart')}</Button>
                </Box>
            </Box>
        </Card>
    );
}

export default HeaderComponent;