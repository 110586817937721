import React from 'react';
import {useNavigate} from "react-router-dom";
import CartAPI from "../../../../common/handlers/api-handlers/CartAPI";
import CartStepper from "./CartStepper";
import { useRequestContext} from "../../../../common/providers/RequestContext";


function CartPrepared() {

    const navigate = useNavigate();
    const location = window.location.pathname;

    const splitted = location.split('/');

    const { setRequestData, requestData, setRequestUrl } = useRequestContext();

    const [ requestUrlData, setRequestUrlData ] = React.useState(null);

    const cartAPI = new CartAPI();

    const cleanUpUrl = () => {
        const _first = splitted[1];
        const _sec = splitted[2];

        if (_first !== 'cart' && _sec !== 'prepared') {
            navigate('/cart')
        }

        // console.log(splitted)

        try {
            const _identifier = splitted[3];
            const _access = splitted[4];

            if (_identifier === undefined && _identifier === '') {
                throw new Error('Invalid URL')
            }

            if (_access === undefined && _access === '') {
                throw new Error('Invalid URL')
            }

            setRequestUrlData({
                'access': _access,
                'identifier': _identifier,
            })
        } catch (e) {
            // console.log(e)
            navigate('/cart')
        }
    }

    React.useEffect(() => {
        setRequestUrl();
        cleanUpUrl()
    }, [])

    React.useEffect(() => {
        if (requestUrlData !== null) {
            // console.log(request_data);

            cartAPI.getPreparedCart(requestUrlData).then((response) => {
                const [success, data] = response;
                if (!success) {
                    navigate('/cart')
                    return;
                } else {
                    const data_set = data.data;
                    setRequestData(data_set)
                }
            });
        }
    }, [requestUrlData])

    if (requestData === null) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    return (
        <CartStepper initActiveStep={2} initCartType={'request'}/>
    );
}

// http://192.168.2.70:2000/cart/prepared/SK6493D1/33363736656632323534333734336639613834326235616634373833393733372e33323330333233343264333033383264333133373534333133343361333533373361333533373265333233353335333833373336

export default CartPrepared;