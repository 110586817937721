const sxTable = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
    pb: 2
}

const sxRow = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: 10,
}

const sxCell = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
}

export {
    sxTable,
    sxRow,
    sxCell,
}