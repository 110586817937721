import React, {useEffect, useState} from 'react';
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import PaymentAPI from "../../../../../../../common/handlers/api-handlers/PaymentAPI";
import StripeCheckoutForm from "./StripeCheckoutForm";


function StripeCheckout({ address }) {
    const [clientSecret, setClientSecret] = useState("");
    const [publishableKey, setPublishableKey] = useState("");
    const [stripePromise, setStripePromise] = useState(null);
    const API = new PaymentAPI();

    const fetchPaymentIntend = async () => {

        await API.stripeInit()
            .then(async ([success, response]) => {
                if (!success) {
                    return;
                } else {
                    setPublishableKey(response.public_key);

                    await API.createPaymentIntent('stripe')
                        .then(([succ, resp]) => {
                            if (!succ) {
                                return;
                            }
                            setClientSecret(resp.client_secret);
                        });

                }
            })
    }

    useEffect(() => {
        fetchPaymentIntend();
    }, []);

    useEffect(() => {
        if (publishableKey !== "") {
            setStripePromise(loadStripe(publishableKey));
        }
    }, [publishableKey]);

    const appearance = {
        theme: 'flat',
    };

    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div className="stripe-payment-api" style={{ width: '100%', maxWidth: '700px' }}>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <StripeCheckoutForm address={address} />
                </Elements>
            )}
        </div>
    );
}

export default StripeCheckout;