import APICall from "../APICall";

class PersonAPI extends APICall {

    constructor() {
        super();
        this.apiType = 'person'
    }

    async makeTestCall() {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'test',
                route: 'get',
                additionalRouteParams: [
                    '100', '200', '300'
                ],
            },
            query: null
        });
    }

    async getPersonForm({path=[]}) {
        await super.fetchConfig();

        const newPath = [];
        path.map((pathElement) => {
            if (pathElement !== null) {
                newPath.push(pathElement);
            }
        })

        return await super.makeGetRequest({
            request: {
                apiType: this.apiType,
                route: 'personForm',
                additionalRouteParams: path,
            },
            query: null
        });
    }

    async getAddPersonForm() {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: this.apiType,
                route: 'addPersonFields',
                additionalRouteParams: [],
            },
            query: [{
                key: 'voluntary',
                value: true
            }]
        });
    }

}


export default PersonAPI;