import React from 'react';
import {NavLink} from "react-router-dom";
import {Link, Tooltip, Zoom} from "@mui/material";

function LogoMenuItem({
    img_src, maxHeight, destination='/'
                      }) {
    return (
        <Link component={NavLink} to={destination} sx={{
            color: '#ffffff',
            '&:hover': {
                color: '#182254',
                textDecoration: 'none',
            }
        }} underline="none">
            <Tooltip title={'Start'} TransitionComponent={Zoom} arrow>
                <img src={'/' + img_src} alt={"Logo"} style={{height: maxHeight}} />
            </Tooltip>
        </Link>
    );
}

export default LogoMenuItem;