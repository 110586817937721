import React from 'react';
import {Close, Menu, Person, ShoppingCart} from "@mui/icons-material";
import {Badge, Box, Drawer, IconButton, Link, MenuItem, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";

function MobileMenuItem({item, closeMenu}) {
    return (
        <Link component={NavLink} to={item.link} style={{ textDecoration: 'none', color: 'inherit'}}>
            <MenuItem
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    borderRadius: '10px',
                    '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        cursor: 'pointer',
                    }
                }}
                onClick={closeMenu}
            >
                <Typography>{item.description}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {
                        (item.displayIcon) ? (
                            item.icon
                        ) : ( <></> )
                    }
                </Box>
            </MenuItem>
        </Link>
    );
}

function MobileMenu({menuItems, cartAmount}) {

    // open the mobile menu as a drawer
    const [openMenu, setOpenMenu] = React.useState(false);

    const openMobileMenu = () => {
        setOpenMenu(!openMenu);
    }

    return (
        <>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={openMobileMenu}
                sx={{mr: 2}}
            >
                <Badge badgeContent={cartAmount} sx={{
                    '& .MuiBadge-badge': {
                        backgroundColor: '#ce4242',
                        fontWeight: 'bold',
                        color: '#ffffff',
                    },
                }}>
                    <Menu sx={{ fontSize: '35px' }} />
                </Badge>
            </IconButton>
            <Drawer
                anchor={'right'}
                open={openMenu}
                onClose={openMobileMenu}
                elevation={10}
            >
                <Box
                    sx={{
                        width: 250,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        padding: '20px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={openMobileMenu}
                        >
                            <Close sx={{ fontSize: '25px' }} />
                        </IconButton>
                        <Typography variant={'h6'}>Menü</Typography>
                        <Link to={'/cart'} component={NavLink}>
                            <IconButton
                                sx={{color: '#000000'}}
                                size={'large'}
                                onClick={openMobileMenu}
                            >
                                <Badge badgeContent={cartAmount} sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: '#ce4242',
                                        fontWeight: 'bold',
                                        color: '#ffffff',
                                    },
                                }}>
                                    <ShoppingCart sx={{fontSize: '25px'}}/>
                                </Badge>
                            </IconButton>
                        </Link>
                        <IconButton sx={{color: '#000000'}} size={'large'}>
                            <Person sx={{fontSize: '25px'}}/>
                        </IconButton>
                    </Box>
                    {
                        menuItems.map((item, index) => {
                            return (
                                <MobileMenuItem
                                    key={"mobile-menu-item" + index}
                                    item={item}
                                    closeMenu={openMobileMenu}
                                />
                            )
                        })
                    }
                </Box>
            </Drawer>
        </>
    );
}

export default MobileMenu;