import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


// fetch('/localization/translations.json')
//     .then((response) => response.json())
//     .then((data) => {
//         i18n
//             .use(initReactI18next)
//             .init({
//                 resources: data,
//                 lng: 'de', // default language
//                 fallbackLng: 'de', // use English if translation is not available
//                 interpolation: {
//                     escapeValue: false, // not needed for React as it escapes by default
//                 },
//             });
//     });
//
// export default i18n;

// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

const loadTranslations = () => {
    return fetch('/localization/translations.json')
        .then((response) => response.json())
        .then((translations) => {
            i18n
                .use(initReactI18next)
                .init({
                    resources: translations,
                    lng: 'de',
                    fallbackLng: 'de',
                    interpolation: {
                        escapeValue: false,
                    },
                });
        });
};

export { loadTranslations };
export default i18n;
